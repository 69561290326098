/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: absolute;
    z-index: 2;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #43425d;
    color: #43425d;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 95px;
    margin-top: 20px;
}

.dot-flashing-left-and-above {
    margin-left: 30px !important;
    margin-top: 1px !important;
}

@media screen and (max-width: 749px) {
    .dot-flashing {
        margin-left: 40%;
    }
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #43425d;
    color: #43425d;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #43425d;
    color: #43425d;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #43425d;
    }
    50%,
    100% {
        background-color: #ebe6ff;
    }
}
