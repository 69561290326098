.statusColumn {
    width: 110px;
    min-width: 110px;
    text-align: center;
}

.childLabel {
    font-weight: 300 !important;
}
.buttonText {
    text-transform: none !important;
    background-color: transparent !important;
}

@media screen and (max-width: 599px) {
    .statusColumn {
        width: 77px;
        min-width: 77px;
    }
}
