.container {
    margin-top: 30px !important;
    width: 100%;
    max-width: 450px;
}

@media screen and (min-width: 960px) {
    .container {
        margin-top: -50px !important;
    }
}

.formsContainer {
    margin-top: 25px !important;
}

.formItem {
    margin-top: 25px !important;
}

.nameContainer {
    display: flex;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
}

.btnContainer {
    margin-top: 15px !important;
}

.dots {
    margin: 40px auto !important;
    display: flex;
    justify-content: center;
    transform: translateX(-50px);
}

.footerContainer {
    margin-top: 30px !important;
    font-size: 13px !important;
}

.doneBtn {
    width: 100%;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    text-transform: none !important;
    font-weight: 300 !important;

    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.accountContainer {
    margin-top: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    border-radius: 4px;
}

.cardTitle {
    display: inline;
    margin: auto !important;
}
.editIcon {
    cursor: pointer;
    font-size: 20px !important;
}

.icon {
    width: 70px !important;
    height: 70px !important;
    display: inline-flex !important;
}

.cardContainer {
    margin-top: 20px;
}

.modalTitle {
    text-align: center;
}

.modalText {
    font-size: 24px;
    color: #43425d;
}

.confirmButton {
    margin: auto !important;
    background-color: #43425d !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    width: 100px;
}
.codebgcolor {
    background-color: #dcdcdc !important;
}
.phoneInput {
    background-color: #dcdcdc !important;
}
.enablePhoneInput {
    background-color: #ffffff !important;
}
.detailContainer {
    padding: 10px 0px 10px 0px !important;
}
.termsandconditions {
    color: #43a5f5;
    cursor: pointer;
}

.remindMeLater {
    text-decoration: underline;
    font-size: 14px;
    color: grey;
    cursor: pointer;
    margin-bottom: 20px;
}

@media screen and (max-width: 599px) {
    .icon {
        width: 35px !important;
        height: 35px !important;
    }
}
@media screen and (max-width: 320px) {
    .cardTitle {
        margin-left: 20px !important;
    }
}
