.selectStatus {
    width: 100% !important;
}
.contractLabels {
    margin-top: 100px !important;
}
.numberInput {
    height: 36px;
    background-color: white;
    width: 70%;
    padding: 0;
    border-radius: 4;
    position: 'relative';
    // background-color: theme.palette.common.white;
    border: '1px solid #e8e9ec';
    font-size: 16;
}
.requiredIcon {
    color: red;
    margin-left: 4px;
}

.labelText {
    display: flex;
    justify-content: left;
}

.updatebtn {
    width: 40%;
    margin-left: 30% !important;
    margin-bottom: 10px !important;
    margin-top: 5% !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.mainGrid {
    margin-left: 15%;
}
@media screen and (max-width: 600px) {
    .mainGrid {
        margin-left: 10%;
    }
    .numberInput {
        width: 90%;
    }
}
