.resetPasswordForm {
    position: absolute;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 200px;
}

@media screen and (max-width: 600px) {
    .resetPasswordForm {
        top: 170px;
    }
}

@media screen and (max-width: 600px) {
    .resetPasswordForm {
        width: 300px;
        margin-left: -150px;
    }
}

@media screen and (max-width: 400px) {
    .resetPasswordForm {
        width: 240px;
        margin-left: -120px;
    }
}

.gridContainer {
    margin-bottom: 10px !important;
}

.resetPasswordFormButton {
    text-align: center;
    margin-bottom: 10px;
    button {
        width: 100%;
    }
}

.resetPasswordButton {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.alert {
    width: 100%;
    text-align: center;
}

.loading {
    margin-right: 16px;
    text-align: center;
}
