.dialogContent {
    height: 264px;
    max-width: 100% !important;
    overflow: hidden !important;
}
.refundDialog {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
.refundDialogDiv {
    overflow: hidden;
}
.refundReason {
    flex-wrap: nowrap !important;
}
.refundReasonOptions {
    padding: 5px !important;
}

.title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}

.withdrawalText {
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 30px;
}

.withdrawalAmount {
    font-weight: bold;
}

.grid {
    padding-left: 17px;
}

.headerDivider {
    height: 0.5px;
    width: 122%;
    margin-left: -24px;
    margin-top: 15px;
    margin-right: 150px;
    background-color: lightgray;
}

.withdrawalArrivingText {
    padding-bottom: 20px;
    max-width: 100%;
}

.descriptionContainer {
    display: flex;
    margin-top: 30px;
}

.description {
    font-size: 12px;
    color: grey;
}

.buttonsContainer {
    margin-bottom: 30px;
}

.button {
    width: 88%;
}

.topPadding {
    padding: 10px 0 40px;
    height: 10px;
    width: 350px;
}

.customOutlineInput {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e8e9ec;
    font-size: 15px;
    height: 35px;
    width: 83%;
}

.reasonSelect {
    margin-left: 2px !important;
    width: 40%;
}

.alertError {
    margin: 0 20px;
}
.radioButtons {
    font-size: 12px !important;
}

@media screen and (max-width: 640px) {
    .customOutlineInput {
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #e8e9ec;
        font-size: 15px;
        height: 35px;
        width: 100%;
    }
    .dialogContent {
        height: 364px;
        max-width: 100% !important;
        overflow: hidden !important;
    }
    .reasonSelect {
        width: 50%;
    }
    .reasonInput {
        width: 100% !important;
    }
    .refundDialog {
        padding: 3px;
        width: 100%;
    }
    .topPadding {
        padding-top: 0px;
        height: 10px;
        width: 100%;
    }
    .refundReasonOptions {
        padding: 0px !important;
        margin: -7px !important;
    }
}
