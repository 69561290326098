.codeInput {
    width: 100% !important;
}

.codeInputIreland {
    width: 550px !important;
}

.codeInputIreland > div > input {
    text-transform: uppercase;
    font-size: 17px;
}

.notificationContainer {
    border-radius: 4px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.iconUnverified {
    margin: 0 20px 0 30px;
    font-size: 23px !important;
    color: #f44336 !important;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
}

@media screen and (max-width: 599px) {
    .codeInputIreland {
        width: 280px !important;
    }
    .codeInputIreland > div > input {
        margin-bottom: 2px;
    }
    .iconContainer {
        margin-top: 0 !important;
    }
    .notificationContainer {
        margin-top: 20px;
    }
}

@media screen and (max-width: 399px) {
    .codeInputIreland {
        width: 250px !important;
    }
}

.codeInputIreland > div > input:first-child,
.codeInputIreland > div > input:nth-child(2) {
    pointer-events: none;
    background-color: lightgray;
}

.codeInputUnitedState {
    width: 500px !important;
}

.codeInputUnitedState > div > input {
    font-size: 17px;
}

.showMessageStyle {
    margin-top: 20px;
}

@media screen and (max-width: 649px) {
    .codeInputUnitedState {
        width: 250px !important;
    }
    .codeInputUnitedState > div > input {
        margin-bottom: 2px;
    }
}

@media screen and (max-width: 399px) {
    .codeInputUnitedState {
        width: 230px !important;
    }
}

.errorIcon {
    font-size: 40px !important;
    color: #f44336 !important;
}

.validIcon {
    color: #00cc00;
    width: 50px !important;
    height: 50px !important;
}

@media screen and (max-width: 399px) {
    .errorIcon {
        width: 25px !important;
        height: 25px !important;
    }
    .validIcon {
        width: 25px !important;
        height: 25px !important;
    }
}

.accountContainer {
    margin-top: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    border-radius: 4px;
}

.checkBoxLabel {
    margin-bottom: 0;
}

.bankAccountValidationError {
    padding-left: 10px;
}
