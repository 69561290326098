.accordionItem {
    width: 100%;
    border-radius: 4px !important;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.accordionContent {
    min-height: 40px !important;
}

.accordionContent > * {
    margin: 0 !important;
}

.expandButton {
    padding-top: 5px !important;
}

.apiKeys {
    display: inline;
    margin-left: 20px !important;
    padding-top: 5px !important;
}

.closeIcon {
    margin-left: auto;
    margin-right: 10px;
}
