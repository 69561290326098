$base-color: #e8e9ec;
$svg-color: #a0aac1;

.stepsContainer {
    margin-top: 20px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid $base-color;
}

.accardionItem {
    box-shadow: none !important;

    border-radius: 0px !important;
    width: 100%;
}

.list {
    padding-bottom: 0 !important;
}

.listItem {
    border-bottom: 1px solid $base-color;
    min-height: 61px;
}

.listItemLast {
    border-bottom: none !important;
    min-height: 61px;
}

.btnText {
    text-transform: none;
}

.mainTitle {
    font-size: 21px;
    font-weight: bold;
}

.accordionDetails {
    display: flex;
    flex-direction: column;
}

.accordionDetailsBtn {
    width: fit-content;
    margin-left: 40px !important;
    font-weight: 100 !important;
}

.textBold {
    font-weight: bold !important;
}

.listItemContainer {
    display: flex;
    padding-left: 16px;
    padding-left: 16px;
    align-items: center;
    svg {
        color: $svg-color;
        font-size: 18px;
    }
}

.summaryContainer {
    display: flex;
}
.itemContainerText {
    margin-left: 30px;
}

.linkFont {
    color: #6e6ca8 !important;
}

.expandedBackGroundColor {
    background-color: #f5f9fb !important;
}
.closeBtn {
    margin-left: auto;
    cursor: pointer;
    svg {
        color: $svg-color;
        font-size: 18px;
    }
    &:active {
        transform: translateY(1px);
    }
}
.tableContainer {
    height: 215px;
    padding: 20px;
    border-radius: 4px !important;
    margin-top: 20px;
    display: flex;
    padding: 40px;
    box-shadow: none !important;
    border: 1px solid $base-color;
}
