.canonicalResellerManagementContainer {
    margin-top: 30px;
}
.pageTitle {
    padding: 16px;
}
.pageTitleContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.canonicalResellerManagementHeader {
    font-size: 20px !important;
    font-weight: bold !important;
}
.canonicalResellerContainer {
    display: flex;
    margin-top: 1.5%;
}
.canonicalResellerIcon {
    margin-left: 1%;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
    }
}

.canonicalResellerName {
    font-size: 18px !important;
    word-break: break-all;
}

.merchantListNone {
    margin-left: 440px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.merchantName {
    min-width: 210px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.loading {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.resellerManagemnt {
    padding: 15px;
}

.dilogbtn {
    width: 90px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.cancelBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}

.checkBoxLabel1 {
    margin-bottom: 0;
}
.button {
    color: white !important;
    text-decoration: none !important;
}
.resellerButton {
    color: white !important;
    text-decoration: none !important;
}
