.stepper {
    float: right !important;
    background: none !important;
    padding-top: 70px !important;
    padding-right: 10% !important;
    margin-bottom: 40px !important;
}

.missingDetailMessage {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 14px;
}

@media screen and (min-width: 960px) {
    .stepper {
        padding-top: 0 !important;
    }
}

@media screen and (max-width: 959px) {
    .stepper {
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-top: 20px !important;
    }
}

@media screen and (max-width: 599px) {
    .stepper {
        width: 100%;
    }
}
