.autoWithdrawalStyle {
    justify-content: space-around;
    align-items: center;
}

.autoWithdrawalContainer {
    display: flex;
    width: 220px;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

@media screen and (max-width: 749px) {
    .autoWithdrawalContainer {
        margin-top: 10px;
    }

    .autoWithdrawalStyle {
        justify-content: space-between;
    }
}
