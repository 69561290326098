.dialogContent {
    height: 225px;
}

.supplierSelect {
    width: 207px;
}

.amountContainer {
    padding-top: 20px;
}

.descriptionContainer {
    padding-top: 20px;
}

.amountInput {
    height: 34px;
    width: 183px;
}

.title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}

.grid {
    padding-left: 17px;
}

.headerDivider {
    height: 0.5px;
    width: 600px;
    margin-left: -24px;
    margin-top: 15px;
    margin-right: 150px;
    background-color: lightgray;
}

.buttonsContainer {
    margin-bottom: 30px;
}

.button {
    width: 88%;
}

.alertError {
    margin-top: 7px;
}
.topPadding {
    padding-top: 20px;
    height: 10px;
    width: 350px;
}
.action_items__input__balance {
    margin-right: -8px;
}
.labelText {
    display: flex;
}
