.searchUserForm {
    text-align: center;
}

.loadingSubmit {
    float: right;
    margin-right: 10px;
}

.searchBtn {
    width: 70%;
    margin-left: 15%;
}

.userResult {
    margin-top: 20px;

    .inviteUserBtn {
        margin-left: 20px;
    }
}

.namesColumn {
    padding-top: 4px;
}

.emailColumn {
    padding-top: 4px;
}

.names {
    float: left;
    margin-left: 10px;
}

.selectBtn {
    float: right;
    margin-right: 5px;
}

.alert {
    margin: 20px;
    width: 100%;
}

.selectRole {
    display: inline-block;
    padding-top: 4px;
    cursor: pointer;
    height: 30px;
    width: 200px;
}
