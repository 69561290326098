.container {
    padding: 0 3% 100px 0;
    overflow: auto;
    width: 100% !important;
}
.header {
    background-color: rgb(238, 238, 238);
    z-index: 0;
}
.top {
    margin: 10px !important;
    margin-right: 3% !important;
    margin-bottom: 20px !important;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.noData {
    margin: 10px;
}
.tableCell {
    min-width: 210px !important;
}
.row {
    z-index: 0 !important;
}
.mainContainer {
    margin-top: 30px;
    padding: 0 !important;
    border-top: 1px #b0b0b0 solid;
}
.toolTipCell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.noneData {
    text-align: center;
    padding-bottom: 118px !important;
    padding-top: 118px !important;
}
.rangeDiv {
    text-align: center;
    width: 100%;
    margin: auto;
    user-select: none;
    padding: 5px 5px !important;
}
.rangeSelect {
    font-size: 16px !important;
    padding-right: 20px;
    text-align: right !important;
}

.TableContainer {
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    overflow-x: auto;
    width: 100%;
}

.searchButton {
    margin-left: 15px !important;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.title {
    font-size: 21px;
    font-weight: bold;
}
.tableSearch {
    padding: 5px 15px !important;
    margin: 5px;
}
.searchMerchants {
    background-color: white;
    min-width: 300px !important;
    margin-right: 20px !important;
}
.search_form {
    margin: 20px 20px;
}
.rowstyle {
    cursor: pointer;
}
@media screen and (min-width: 320px) and(max-width: 599px) {
    .searchMerchants {
        min-width: 200px !important;
    }
}
@media screen and (max-width: 320px) {
    .searchMerchants {
        min-width: 100px !important;
    }
}
