.title {
    font-weight: bold !important;
    font-size: 30px !important;
    text-align: center;
    margin-top: 20px !important;
}

.subTitle {
    text-align: center;
    font-size: 15px !important;
    margin-bottom: 30px !important;
}

.cardsContainer {
    display: flex;
    justify-content: center;
}

.cards {
    width: 450px !important;
    margin-bottom: 20px !important;
}

.btn {
    width: 100%;
}

.btnsubmit {
    width: 100%;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
