.alertError {
    margin-top: 20px;
    width: 100%;
}

.actionsContainer {
    margin-bottom: 10px;
    margin-right: 10px;
}

.loading {
    margin-right: 50px;
}
