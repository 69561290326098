.calenderDiv {
    display: flex;
    width: 100%;
    color: #8783b5;
}
.rangeDiv {
    text-align: center;
    width: 100%;
    margin: auto;
    user-select: none;
}
.datepicker {
    text-align: center;
}

.leftAlign {
    text-align: left;
}
.datePickerAlignment {
    text-align: end;
    width: 75%;
    margin: auto;
}

.rightAlign {
    text-align: right;
}
.contentwrap {
    border: 1px solid #e5e9f2;
    padding: 3px 3px;
    border-radius: 4px;
    min-width: 180px;
    text-align: left;
    font-size: 0.9em;
    letter-spacing: 0.2px;
    justify-content: center;
    box-sizing: border-box;
    font-weight: bold;
}
