.merchantContent {
    margin-right: 3%;
    margin-bottom: 20px;
}

@media screen and (max-width: 959px) {
    .merchantContent {
        margin-left: 3%;
    }
}

@media screen and (min-width: 1259px) {
    .merchantContent {
        margin-right: 5%;
    }
}
