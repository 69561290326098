.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.logo__container {
    background-color: #f8f9fb;
    width: 41%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 51%;
        width: 100%;
    }

    > img {
        height: 30%;
        width: auto;

        @media screen and (max-width: 768px) {
            height: 45%;
            width: auto;
        }
    }
}

.content__container {
    background-color: white;
    width: 59%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
        margin-top: -80px;
        height: 59%;
        width: 100%;
        border-radius: 30px 30px 0 0;
        background: #fff;
        box-shadow: 0px 4px 30px 0px rgba(197, 197, 197, 0.25), 0px 4px 12px 0px rgba(197, 197, 197, 0.2);
        gap: 30px;
    }

    .content__container__text {
        width: 38%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 768px) {
            gap: 10px;
            width: 80%;
        }

        .content__container__text__main {
            color: #1d1d1f;
            text-align: center;
            font-family: 'Lato', sans-serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 768px) {
                font-size: 26px;
            }
        }

        .content__container__text__para {
            color: #1d1d1f;
            text-align: center;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;

            @media screen and(max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    .content__container__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .content__container__cta__button {
            border-radius: 30px;
            background: #005896;
            display: inline-flex;
            padding: 7px 30px;
            align-items: center;
            gap: 6px;
            text-transform: none;
            color: #fff;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 768px) {
                width: 80%;
            }
        }

        > p {
            color: #878da3;
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}
