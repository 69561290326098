.documentManagementContainer {
    margin-top: 30px;
}
.pageTitle {
    padding: 16px;
}

.documentManagementHeader {
    font-size: 20px !important;
    font-weight: bold !important;
}

.managementArea {
    display: flex;
}

.merchantDetails {
    max-width: 30%;
    flex-basis: 30%;
    border-right: 1px solid rgba(224, 224, 224, 1);
    margin: 20px 0;
}

.documentManagementButtonsContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    & button {
        margin: 0 20px;
    }
}

.sendNotesContainer {
    padding: 5px;
    margin: 20px auto 5px auto;
}

.sendNotesButton {
    padding: 5px;
    margin: 10px auto 0 auto !important;
    min-width: 200px !important;
    width: 32%;
}

.sendNotesError {
    padding: 5px;
    margin: 5px auto 0 auto;
    width: 100%;
    justify-content: center;
}

.noteInput {
    padding-bottom: 10px !important;
    padding-top: 10px;
}
.icon {
    margin-left: auto !important;
    margin-right: 1px !important;
    margin-top: 15px !important;
}
.documentTitle {
    margin-bottom: 10px !important;
}
.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
@media (min-width: 1280px) {
    .sendNotesContainer {
        flex-grow: 0 !important;
        max-width: 66.666667% !important;
        flex-basis: 66.666667% !important;
    }
}
