@import '../../styles/shared/layout';

.header {
    background-color: #d7edff;
    height: $header-height;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
}

.logo {
    display: inline-block;
    margin-top: 5px;
    width: calc(100% - 150px);
    text-align: center;

    img {
        width: 150px;
        height: 50px;
        cursor: pointer;
    }
}

.iconUser {
    float: right;
    margin-top: 10px;
    margin-right: 3%;
    cursor: pointer;
}

.merchantHeader {
    margin-top: 10px;
    height: 100px;
    justify-content: left;
    display: flex;
}

.homeLogo {
    height: 100%;
    width: 120px !important;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 70px;
    cursor: pointer;
    img {
        width: inherit;
    }
}

.omniPayLogoStyle {
    width: 150px !important;
}
.banner {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
}
.listItemContainer {
    height: auto;
    border-radius: 4px;
    width: 100%;
    display: block;
    justify-content: center;
    padding: 10px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    margin: auto;
}

.bubbleMessage {
    font-size: 14px !important;
}
@media (max-width: 959px) {
    .homeLogo {
        width: 100px !important;
        margin-left: 0px;
    }

    .merchantHeader {
        margin-top: 0px;
    }
}
@media (max-width: 600px) {
    .homeLogo {
        margin-top: 18% !important;
        display: block;
        width: 100px !important;
        height: 30px !important;
        margin-left: 5px;
    }
}

.merchantLabel {
    width: 110px;
    font-weight: bold;
    background-color: rgb(14, 161, 112);
    margin-left: 80px;
    padding: 5px;
    margin-top: 30px;
}
