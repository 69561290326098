.textFieldName {
    width: 100%;
}

.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.selectCountry {
    width: 100% !important;
}

.nextButton {
    width: 90px;
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.cancelBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}
