.modalContainer {
    width: 100%;
    max-width: 400 !important;
    min-width: 250px !important;
    align-items: center;
    padding: 20px;
}
.containerStyles {
    padding-bottom: 20px;
    padding-left: 20px;
}
.yesBtn {
    width: 30%;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}
.acquirerInput {
    margin-right: 10px;
}

.viewerContainer {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .yesBtn {
        width: 60%; /* Make the button take full width on mobile */
    }
}
