.listContainer {
    width: 100%;
    background-color: #ffffff;
    color: #43425d;
    border-radius: 4px;
    border: 1px lightgray solid;
    font-weight: bold;
    z-index: 1;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.listItem {
    border-top: 1px lightgray solid !important;
}

.listIcon {
    margin-right: 10px;
}

.merchantNameContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.merchantName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.overflowCollapse {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 258px;
    background-color: #ffffff;
    z-index: 3;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: auto;
}

.overflowCollapse::-webkit-scrollbar {
    width: 0.4em;
}

.overflowCollapse::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.overflowCollapse::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.listItemSearchMerchants {
    padding: 0px !important;
}

.searchMerchants {
    width: 100%;
}

.loading {
    height: 26px;
}

.createNewMerchant {
    font-size: 17px;
}

.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.listContentContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

// .merchantName:hover {
//     content: attr(data-hover);
// }

// .merchantName:hover:after {
//     content: attr(data-hover);
// }

.editIcon {
    width: 18px !important;
    height: 18px !important;
}

@media screen and (max-width: 599px) {
    .merchantName {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .merchantName:hover {
        overflow: visible;
        -webkit-line-clamp: unset;
    }
    .merchantName:hover:after {
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
}

.merchantName:hover {
    overflow: visible;
    -webkit-line-clamp: unset;
}
.merchantName:hover:after {
    overflow: hidden;
    -webkit-line-clamp: 3;
}

@media (hover: hover) and (pointer: fine) {
    .merchantName:hover {
        overflow: visible;
        -webkit-line-clamp: unset;
    }
    .merchantName:hover:after {
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
}
