.copyIconContainer {
    display: flex;
    max-width: 40px !important;
    padding-left: 15px;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}

.textInput {
    box-shadow: none !important;
    font-size: 0.875rem !important;
}

.createContainer {
    margin-right: 10px !important;
}
