.paper {
    width: 100%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.dilogbtn {
    width: 57%;
}
.leavebtn {
    width: 41%;
}
.btnContainer {
    margin-right: 10px;
}
