.bankDetailsContainer {
    margin: 50px auto 0 auto !important;
    max-width: 90% !important;
}

.bankFields {
    margin-top: 20px;
    user-select: none;
    border: none;
}

.btnContainer {
    .btnContainer {
        text-align: center;
        text-align: center;
        margin-top: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-bottom: 40px;
    }
}

.formTitle {
    font-weight: bold !important;
    font-size: 30px !important;
}

@media screen and (max-width: 599px) {
    .bankDetailsContainer {
        max-width: 80% !important;
    }
}
