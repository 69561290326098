.termsAndConditionContainer {
    margin-top: 30px;
}

.termsAndConditionsInSideModal {
    margin-top: 0px;
}

.centerAlignHeader {
    text-align: center;
    font-size: 20px !important;
    font-weight: bold !important;
}

.pageTitle {
    padding: 16px;
}

.termsAndConditionHeader {
    font-size: 20px !important;
    font-weight: bold !important;
}

.iframeContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.iframePdf {
    width: 100%;
    height: 460px;
    overflow: hidden;
}

.submitInput {
    margin-bottom: 20px !important;
}

.loading {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media screen and (max-width: 599px) {
    .iframePdf {
        height: 100%;
    }
}
