.customInput {
    width: 100%;
}
.errorBorder {
    input {
        border-bottom-color: red;
    }
}

.inputAreas {
    border-bottom-color: red !important;
    fieldset: {
        border: 2px solid red !important;
    }
}
