.row {
    width: 20%;
}
.paper {
    max-height: 70%;
    margin-top: 5%;
}
.header {
    position: sticky;
    top: 0;
}
.topbar {
    margin-top: 3%;
}
.tableheader {
    font-size: 24px;
    margin-top: 2%;
    font-weight: 600;
    @media (max-width: 900px) {
        font-size: 19px;
        margin-top: 4%;
    }
}
.exportPdfButton {
    height: 10%;
    margin-top: 2% !important;
    margin-left: 2% !important;
}
.topright {
    line-height: 60%;
    text-align: right;
    margin-right: 0%;
    @media (max-width: 900px) {
        text-align: center;
        line-height: 20%;
    }
}
.address {
    font-size: 15px;
    font-weight: 400;
    line-height: 0.5;
    cursor: default;
    :last-child {
        cursor: pointer;
    }
}
@media screen and (max-width: 900px) {
    .topright {
        text-align: right;
        line-height: 20%;
    }
    .tableheader {
        font-size: 18px;
    }
}
@media screen and (max-width: 640px) {
    .topbar {
        padding: 10px;
    }
    .address {
        font-size: 12px;
        margin: 0;
        line-height: 1.5;
    }
    .topright {
        margin-top: 30px;
    }
}
