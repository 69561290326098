.navigationBar {
    position: absolute;
    top: 0;
    width: 110px !important;
    margin-top: 23px;

    .navigationItem {
        display: flex;
        padding-bottom: 7px;
        border-bottom: 3px solid currentColor;

        .navigationText {
            font-size: 18px;
            font-weight: 500;
            margin-left: 5px;
        }
    }
}

@media screen and (max-width: 959px) {
    .navigationBar {
        margin-left: 20% !important;
    }
}

@media screen and (max-width: 599px) {
    .navigationBar {
        margin-left: 30% !important;
    }
}

@media screen and (max-width: 399px) {
    .navigationBar {
        margin-left: 40% !important;
    }
}
