.loader__component {
    animation: rotate 1.48s infinite;
    height: 50px;
    width: 50px;
}
.loader__component::before,
.loader__component::after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
}
.loader__component::before {
    animation: ball1 1s infinite;
    background-color: #005896;
    box-shadow: 30px 0 0 #00a59b;
    margin-bottom: 10px;
}
.loader__component::after {
    animation: ball2 1s infinite;
    background-color: #dc9f37;
    box-shadow: 30px 0 0 #c84031;
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.6);
        -moz-transform: rotate(0deg) scale(0.6);
    }
    50% {
        -webkit-transform: rotate(360deg) scale(0.8);
        -moz-transform: rotate(360deg) scale(0.8);
    }
    100% {
        -webkit-transform: rotate(720deg) scale(0.6);
        -moz-transform: rotate(720deg) scale(0.6);
    }
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #005896;
    }
    50% {
        box-shadow: 0 0 0 #005896;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 #005896;
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #00a59b;
    }
    50% {
        box-shadow: 0 0 0 #00a59b;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 #00a59b;
        margin-bottom: 0px;
    }
}
