.formItemGrid {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.select {
    width: 98%;
    margin-right: 1%;
    margin-left: 1%;
}

.uploadBtn {
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-transform: none !important;
    font-weight: 300 !important;
}

.loading {
    text-align: center;
}

.zoneDisabled {
    pointer-events: none;
    opacity: 0.4;
}

.zoneEnabled {
    pointer-events: auto;
    opacity: 0;
}

.dropzoneClass {
    height: 90px;
    min-height: 90px !important;
}

.idNumber {
    padding-bottom: 12px;
}
.cardNumber {
    padding-top: 12px;
}
