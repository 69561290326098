.specialRentDialog {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.dialogTilte {
    display: flex;
    justify-content: space-between;
}
.addbtn {
    width: 95%;
    margin-left: 2% !important;
    margin-bottom: 10px !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.rentValue {
    width: 98%;
    margin-left: 2%;
}
.title {
    padding-bottom: 0px !important;
}
.formContainer {
    padding: 5%;
    padding-top: 0px;
}
.rentfields {
    margin-top: 15px;
    margin-left: 2%;
}
.closeIcon {
    &:hover {
        background-color: #f6f7fa;
        cursor: pointer;
    }
}
.radio_btn {
    color: black;
}
.datePicker {
    border: 1px solid #e9e9ec;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    border-radius: 4px;
    color: gray;
    min-width: 200px;
    text-align: left;
    font-size: 16px;
    width: 100% !important;
    background-color: #fff;
}
@media screen and (max-width: 600px) {
    .datePicker {
        border: 1px solid #e9e9ec;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 4px;
        border-radius: 4px;
        color: gray;
        min-width: 120px;
        text-align: left;
        font-size: 16px;
        width: 100%;
        background-color: #fff;
    }
    .rentValue {
        width: 97%;
        margin-left: 3%;
        //height :10px;
    }
}
