.requestAmountButton {
    color: #fff;
    background-color: #43425d;
    border: none;

    &:hover {
        background-color: #43425d;
    }

    &:disabled {
        color: #aaaaaa;
        background-color: #d8d9dc;
    }
}
