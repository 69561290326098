.alertError {
    position: absolute;
    width: 350px;
    left: 50%;
    bottom: 3%;
    margin-left: -175px;
}

.loading {
    position: absolute;
    left: 50%;
    top: 7%;
    margin-left: -1rem;
}

.homeLogo {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 170px;
    }
}

@media screen and (max-width: 600px) {
    .homeLogo {
        height: 200px;
        img {
            width: 150px;
        }
    }
}
