.btnWrapper {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    &__finishBtn {
        max-width: 450px;
        width: 100%;
        &:not([disabled]) {
            background-color: green !important;
            color: white !important;
        }
    }
}
