.MerchantDetailsContainer {
    display: flex;
    flex-direction: column;
}

.subTitle {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.title {
    padding: 20px 20px 0px;
}

.businessDetailContainer {
    max-width: 100%;
    padding: 20px;
}

.ownerDetailsContainer {
    max-width: 100%;
    padding: 20px;
}

.phoneInput {
    max-width: 100%;
    background-color: gainsboro !important;
    border: none !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.selectMerchantDetails {
    border-radius: 4px !important;
    background-color: white;
    min-width: 250px !important;
    height: 40px;
}

.bankDetails {
    max-width: 100%;
    padding: 20px;
}

.codeInput {
    input:disabled {
        background-color: gainsboro;
        color: rgba(0, 0, 0, 0.38);
    }
}
