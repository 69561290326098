.dialog {
    overflow: hidden !important;
}

.table {
    overflow: scroll !important;
}

.dialogContent {
    overflow: hidden !important;
}
.refundProcess {
    width: 104%;
    margin-left: 4px !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.bannerPara {
    text-align: center;
    font-size: 18px;
    color: red !important;
    font-weight: 600;
}

.dropdown {
    width: 200px;
}

.dialogbtn {
    text-align: center !important;
}
.dialogTitle {
    display: flex;
    justify-content: space-between;
}

.title {
    padding-bottom: 0px !important;
}

.formContainer {
    padding: 5%;
    padding-top: 0px;
}

.closeIcon {
    &:hover {
        background-color: #f6f7fa;
        cursor: pointer;
    }
}

.radio_btn {
    color: black;
}
.datePicker {
    border: 1px solid #e9e9ec;
    padding: 1px 0px 1px 4px !important;
    border-radius: 4px !important;
    color: gray;
    min-width: 200px;
    text-align: left;
    font-size: 16px;
    width: 320px !important;
    background-color: #fff;
    margin-left: -3px !important;
}
.datePickerReason {
    border: 1px solid #ddd !important;
    border-radius: 5px !important;
    padding: 5px 5px 5px 10px !important;
    min-width: 200px;
    text-align: left;
    width: 315px !important;
    background-color: #fff;
    margin-left: 1px !important;
}
@media screen and (max-width: 600px) {
    .datePicker {
        border: 1px solid #e9e9ec;

        padding: 1px 0px 1px 4px !important;
        border-radius: 4px !important;
        color: gray;
        min-width: 120px;
        text-align: left;
        font-size: 16px;
        width: 100% !important;
        background-color: #fff;
    }
    .datePickerReason {
        border: 1px solid #ddd !important;
        border-radius: 5px !important;
        padding: 5px 5px 5px 10px !important;
        min-width: 200px;
        text-align: left;
        width: 99.5% !important;
        background-color: #fff;
    }

    .rentValue {
        width: 97%;
        margin-left: 3%;
    }
    .refundProcess {
        width: 100%;
        margin-left: 4px !important;
        &:not([disabled]) {
            background-color: green !important;
            color: white !important;
        }
    }
}

.select {
    width: 27.5rem !important;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    text-align: left !important;
}
.TableContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    width: 700px !important;
}

.label {
    margin-left: 3px !important;
}
