.menuContainer {
    width: 80%;
}

.menuText {
    margin-left: 15px !important;
    color: #212529 !important;
    font-weight: 400 !important;
}

.developerSeparator {
    font-size: 10px;
}

.activeMenu {
    border-left: 3px solid currentColor !important;

    .menuText {
        color: #43425d !important;
        font-size: 22px !important;
        font-weight: 1000 !important;
        margin-left: 15px;
    }
}

.menuItemContainer {
    margin-left: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
}
