.previewContainer {
    padding: 20px 40px;
    border-radius: 4px !important;
    display: flex;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}

@media screen and (max-width: 959px) {
    .previewContainer {
        margin-top: 0 !important;
    }
}

.previewTxtContainer {
    height: auto;
}

.textBold {
    font-weight: bold;
    font-size: 25px;
}

.welcomeName {
    font-size: 27px !important;
    font-weight: bold !important;
    max-height: 180px;
    overflow: hidden;
}

@media screen and (max-width: 959px) {
    .welcomeName {
        font-size: 25px !important;
        max-height: 150px;
        margin-left: 0px !important;
    }
    .textBold {
        font-size: 20px !important;
    }
    .previewContainer {
        padding: 10px 20px !important;
    }
}

@media screen and (max-width: 399px) {
    .welcomeName {
        font-size: 25px !important;
        max-height: 120px;
        margin-left: 0px !important;
    }

    .nameOnboardContainer {
        //text-align: center !important;
        flex-direction: column !important;
        margin-bottom: 0px !important;
    }
    .onboardBtn {
        display: flex !important;
    }
    .textBold {
        font-size: 20px !important;
    }
    .previewContainer {
        padding: 10px 20px !important;
    }
    .onboardBtn {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.onboardBtn {
    margin-top: 22px !important;
    background-color: #43425d !important;
    text-transform: none !important;
    border: none !important;
    display: flex;
    align-items: center;
    margin-left: 10%;

    p {
        font-weight: bold !important;
        font-size: 0.9375rem;
        margin-left: 10px;
        color: lightgray;
    }
    .iconButton {
        color: lightgray;
    }
    border-radius: 4px;
}

.nameOnboardContainer {
    display: flex;
    text-align: start;
    flex-direction: column;
    margin-bottom: 10px !important;
}

.orangBtn {
    background-color: #cc951e !important;

    p {
        color: #fff !important;
    }
    svg {
        color: #fff !important;
    }
}

.greenBtn {
    background-color: #006600 !important;
    p {
        color: #fff !important;
    }
    svg {
        color: #fff !important;
    }
}

.disabledBtn {
    background-color: #e0e0e0 !important;
    p {
        color: rgba(0, 0, 0, 0.26) !important;
    }
    svg {
        color: rgba(0, 0, 0, 0.26) !important;
    }
}
