.root {
    width: '100%';
}
.paper {
    width: '100%';
}
.noRent {
    text-align: center;
    padding-bottom: 68px;
    padding-top: 68px;
    display: flex;
    margin-left: 45%;
}
.rentTableHeader {
    background-color: #fafafa;
}
.icons {
    display: flex;
    justify-content: space-evenly;
}
.editIcon {
    color: cadetblue;
    cursor: pointer;
    &:hover {
        background-color: #fafafa;
        border-radius: 5px;
    }
}
.specialRentTableDeleteIcon {
    cursor: pointer;
}
