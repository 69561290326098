.radio_btn {
    cursor: pointer;
    padding: 0px;
}

.radio_with_label_container {
    display: grid;
    text-align: center;
    margin-top: -10px;
    flex-grow: 0;
    justify-content: space-between;
}

.radio_container {
    margin-top: -10px;
}

.singleRadioButton {
    flex-grow: 0;
    max-width: 12.5%;
    flex-basis: 12.5%;
}

.roleContainer {
    display: flex;
    align-items: center;
}

.roleName {
    padding-left: 5px;
}

.email_input {
    margin-left: -6%;
    width: 100%;
}

.inviteMembersTitle {
    h2 {
        font-size: 28px;
    }
}
.headerDivider {
    height: 0.5px;
    width: 100%;
    background-color: lightgray;
}

.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.iconUnverified {
    margin: 0 20px 0 30px;
    font-size: 12px !important;
    color: #f44336 !important;
}

.error_icon {
    font-size: 20px !important;
    color: #f44336 !important;
}

.errorMessage {
    font-size: 15px !important;
}
.agentRole {
    background-color: #caf5c9;
    color: '#e67e00';
    padding: 5px;
    font-size: 15px;
    margin-left: 10px;
    border-radius: 5px;
}

.duplicateEmailContainer {
    margin-top: 5px;
    width: 100%;
    border-radius: 4px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 5px 0;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.removeUserButton {
    max-width: 11%;
}

@media screen and (max-width: 599px) {
    .email_input {
        margin-bottom: 12px;
    }
    .userRolesContainer {
        margin-left: -6% !important;
        margin-top: 10px !important;
    }
}

.role_info {
    color: #3c4257;
    font-weight: 400;
    font-size: 16px;
}

.title_info {
    font-weight: 400;
    font-size: 16px;
}

.dialog {
    width: 800px;
    height: 600px;
}
