.modal-backdrop.show {
    opacity: 0.1;
}

.list-group-item-action:focus {
    outline: 0;
}

.modalHeader {
    font-weight: bold;
    font-size: 17px;
    margin-left: 5px;
    background-color: #aed6f9;
    margin: 0;
    padding: 1rem;
}

.styles_react-code-input__CRulA > input {
    border-right: solid 1px #a8adb7 !important;
    border-radius: 4px !important;
}

.default-footer .buttons .select {
    display: none !important;
}
