.container {
    height: 10%;
}

.sideNavContainer {
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    width: 23%;
    border-right: 1px solid #e8e9ec;
    margin-right: 10px;
    background-color: #f6f7fa !important;
}

.contentContainer {
    margin-left: auto !important;
}

.menuIcon {
    width: 55px !important;
    height: 55px !important;
    cursor: pointer;
}
.waFloat {
    position: fixed !important;
    bottom: 25%;
    right: 0;
}

.drawerMenu {
    width: 300px !important;
    margin-top: 10px;
}

.listMerchantsContainer {
    margin: 20px 0 !important;
    margin-right: 3%;
}

.menuIconContainer {
    margin: 20px 0 !important;
    margin-left: 3% !important;
}

.mobileHeader {
    position: fixed;
    top: 0;
    background-color: #f9fbfd;
    z-index: 100;
    width: 100%;
}
.messageWidth {
    width: 96%;
    display: inline-block;
    word-break: break-word;
    border: 2px solid #deb4a9;
    border-bottom: none;
    border-radius: 4px;
}

@media screen and (max-width: 959px) {
    .listMerchantsContainer {
        margin-top: 20px !important;
    }
    .menuIconContainer {
        margin-top: 20px !important;
    }
    .container {
        height: 0px;
        margin-top: 100px;
    }
    .contentContainer {
        margin-top: 15px !important;
    }
}

@media screen and (max-width: 599px) {
    .listMerchantsContainer {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
    .menuIcon {
        width: 30px !important;
        height: 30px !important;
    }
    .menuIconContainer {
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .listMerchantsContainer {
        max-width: 80.66666% !important;
        flex-basis: 80.666666% !important;
    }
    .menuIconContainer {
        max-width: 10.333333% !important;
        flex-basis: 10.333333% !important;
    }
    .container {
        height: 0px;
        margin-top: 65px;
    }
}
@media screen and (min-width: 280px) and (max-width: 720px) {
    .container {
        margin-top: 87px;
    }
    .messageWidth {
        width: 97%;
    }
}
@media screen and (min-width: 540px) and (max-width: 720px) {
    .container {
        margin-top: 67px;
    }
    .messageWidth {
        width: 97%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .messageWidth {
        width: 97%;
    }
}
