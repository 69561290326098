.acquirerInput {
    margin-right: 10px;
}

.acquirerButton {
    margin-top: 20px;
}

.errorText {
    color: red;
}

.acquirerGrid {
    border-top: 1px solid #cccccc;
    margin-top: 20px;
}

.acquirerTopGrid {
    border-top: 1px solid #cccccc;
    margin-top: 20px;
    max-width: 50%;
}

.boldText {
    font-weight: 600;
}
.tables {
    margin-top: 10%;
    margin-bottom: 10%;
}
