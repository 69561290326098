.updateBankInfoContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.container {
    margin-top: 50px;
    &_title {
        font-weight: bold !important;
        font-size: 21px !important;
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }
}

.inputs {
    border: 1px solid #e8e9ec;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
    Label {
        margin-top: 15px;
        color: #43425d;
    }
}

.validationWrapper {
    text-align: center;
}

.validIcon,
.errorIcon {
    width: 50px !important;
    height: 50px !important;
}

.validIcon {
    color: #00cc00;
}

.errorIcon {
    color: red;
}

.codeInput {
    width: 100% !important;
    height: 33px;
    background-color: white !important;
    &__input {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
}

.errorContainer {
    margin-bottom: 30px;
    width: 70%;
    margin-left: 15%;
}

.itemForm,
.validationWrapper {
    margin-top: 10px;
}

.divider {
    margin: 20px 0 !important;
}

.holderName {
    box-sizing: border-box !important;
    height: 33px !important;
    text-indent: 0 !important;
    padding-left: 14px !important;
    width: 300px !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    &:hover {
        border: 1px solid black !important;
    }
    &:focus {
        border: 2px solid black !important;
        box-shadow: none !important;
    }
}

.codeInput {
    width: 100% !important;
}

.codeInputIreland {
    width: 350px !important;
}

.notificationContainer {
    margin-top: 10px;
    width: 100%;
    border-radius: 4px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 5px 0;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
}

.iconUnverified {
    margin: 0 20px 0 30px;
    font-size: 12px !important;
    color: #f44336 !important;
}

.errorIcon {
    font-size: 20px !important;
    color: #f44336 !important;
}

.errorMessage {
    font-size: 15px !important;
}

.existingContainer {
    display: flex;
    justify-content: space-between;
}

.containerItem {
    flex-basis: 50%;
}

.irelandAccountHolderName {
    width: 49%;
}

.formLeftSide {
    flex-basis: 49%;
}

.formRightSide {
    flex-basis: 49%;
}

.updateButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.updateButton {
    width: 50%;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.bsbexistingContainer {
    display: flex;
    justify-content: space-between;
}
.bsbInput {
    width: 100% !important;
}

.updateBankDetailsForm {
    padding: 10px 20px 20px 20px;
}

.pageTitle {
    padding-left: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 599px) {
    .errorContainer {
        margin-bottom: 10px;
        width: 100%;
        margin-left: 0;
    }
    .existingContainer {
        display: flex;
        flex-direction: column;
    }
    .irelandAccountHolderName {
        width: 100%;
    }
    .updateButton {
        width: 100%;
    }
    .holderName {
        width: 100% !important;
    }
}

@media screen and (max-width: 399px) {
    .codeInputIreland {
        width: 250px !important;
    }
    .notificationContainer {
        width: 250px !important;
    }
    .errorIcon {
        width: 25px !important;
        height: 25px !important;
    }
}
