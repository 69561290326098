.copyIconContainer {
    display: flex;
}

.idContainer {
    width: calc(210px + 10px) !important;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}

.textInput {
    border: none !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
}
