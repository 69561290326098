.userManagement {
    padding-top: 30px;
    margin: 0 7%;

    .headerPage {
        box-shadow: 0px 0px 3px 1px gray;
        margin-bottom: 20px;
        padding: 10px 0 15px 0;
        text-align: center;

        .textHeader {
            margin: 0;
        }
    }
}

.username {
    cursor: pointer;
}

.actionsColumn {
    width: 200px;
    text-align: center;

    .assignButton {
        height: 24px;
        padding: 0 10px;
        padding-bottom: 2px;
        display: none;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.entitiesColumn {
    width: 370px;
    padding: 0 !important;

    .entitiesSelect {
        border: 0;
        cursor: pointer;
        height: 53px;

        .circleEntity {
            border: 2px red solid;
        }
    }
}

.switchDisableEnableUser {
    width: 70px;
    text-align: center;
}

.rowUser {
    height: 38px;
}

.rowUser:hover {
    .assignButton {
        display: inline-block;
    }
}

#createUserBtn {
    float: right;
    margin-bottom: 10px;
}
