.noMembers {
    padding: 32px 16px;
    text-align: center;
}
.dialog {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.requestOngoingSpinner {
    margin-right: 16px;
    text-align: center;
}
