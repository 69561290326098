.container {
    padding: 0 2% 0px 0%;
    overflow: auto;
}

.searchPaper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 30px 0;
}

.searchPaper > form {
    display: inline-block;
}
.logo {
    float: right;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.resellerLabel {
    display: inline-block;
    margin: 35px 5px !important;

    color: #212529 !important;
    font-weight: 900 !important;
    font-size: 25px !important;
}

.searchMerchants {
    background-color: white;
    width: 60%;
    margin-right: 20px !important;
}

.newMerchantbutton {
    height: 40px;
    margin-top: 15px !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.selectInput {
    background-color: white;
    margin-left: 10px;
    height: 40px;
}
.formControl {
    margin-top: 20px !important;

    width: 100%;
}
.merchantLink {
    color: #212529;
    &:hover {
        color: #212529;
    }
}

.nameColumn {
    max-width: 195px !important;
    min-width: 195px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 0;
}

.doneIconStyle {
    color: green;
}

.logoImage {
    display: flex;
    justify-content: center;
}

.addressColumn {
    max-width: 200px !important;
    min-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.searchContainer {
    display: flex;
    flex-direction: column;
}
.searchButton {
    height: 40px;
}

.resellerHeader {
    margin-bottom: 50px;
    margin-top: 30px;
}

.logout {
    cursor: pointer;
    line-height: 30px;
}

.menuText {
    display: inline-block;
    margin-left: 5px !important;
    color: #212529 !important;
    font-weight: 500 !important;
}
.loading {
    text-align: center;
    margin-top: 100px;
}
.accessNameButton {
    text-transform: none;
}
.header {
    background-color: rgb(238, 238, 238);
}
.rowData > * > span {
    cursor: default;
}
.rowData > * > span:hover {
    background-color: white !important;
}
.accessButton:hover {
    background-color: #43425d !important;
    color: white;
}
.TableContainer {
    overflow-x: auto !important;
    margin-top: 30px;
}
.radioButton {
    padding: 0px !important;
}
.pageTitle {
    font-weight: 700;
    font-size: 20px;
    color: #43425d;
}
.flexDiv {
    display: flex;
    padding-top: 25px;
}

.merchantName {
    transition: background-color 0.5s;
    background-color: transparent;
    user-select: none;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
    color: #2c7be5;
    &:hover {
        color: purple !important;
    }
}
.datmanMerchantName {
    transition: background-color 0.5s;
    background-color: transparent;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.nameLink {
    color: #43a5f5 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    &:hover {
        color: #212529 !important;
    }
}

.listItemContainer {
    background-color: #f8fbfd;
    margin-top: 20px;
    height: auto;
    min-height: 50px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}
.inputlabel {
    margin-left: 10px;
}
.search {
    position: relative;
    float: left;
}
.iconVerified {
    margin: 0 15px 5px 30px;
}
@media screen and (max-width: 1024px) {
    .searchMerchants {
        width: 40% !important;
    }
}
@media screen and (max-width: 600px) {
    .logo {
        display: none;
    }
    .searchContainer {
        display: flex !important;
        justify-content: center !important;
        margin-top: 15px;
    }
    .searchMerchants {
        width: 70% !important;
    }

    .resellerId {
        display: flex;
        justify-content: center;
    }
    .search {
        order: 2;
        display: flex;
        justify-content: center;
    }
    .searchButton {
        padding: 5px 20px;
    }
    .newMerchantbutton {
        width: 100%;
    }
    .center {
        margin-left: 0;
        display: flex;
        justify-content: center;
    }
    .container {
        padding: 0;
    }
    .selectInput {
        margin-left: 0px;
    }
}
