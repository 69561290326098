.formTitle {
    font-weight: bold !important;
    font-size: 30px !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.formSubTitle {
    font-size: 15px !important;
}
.dots {
    margin: 40px auto !important;
    display: flex;
    justify-content: center;
    transform: translateX(-80px);
}

.iconUnverified {
    margin: 0 20px 0 30px;
}

.error_icon {
    font-size: 23px !important;
    color: #f44336 !important;
}

.listItemContainer {
    height: auto;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    margin-bottom: 20px;
}

.bubbleMessage {
    font-size: 14px !important;
}
