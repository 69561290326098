.updateAccountMypayContainer {
    margin-top: 30px !important;
    border-radius: 4px !important;
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}

.formContainer {
    width: 80%;
    margin-left: 10%;
    margin-top: 50px;
    max-width: 450px;
}

.formTitle {
    font-weight: bold !important;
    font-size: 21px !important;
}

.pageTitle {
    padding-left: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding-top: 16px;
    padding-bottom: 16px !important;
}

.bankDetailsContainer {
    margin: 50px auto 0 auto !important;
    max-width: 90% !important;
}

.bankFields {
    margin-top: 20px;
    user-select: none;
    border: none;
}

.btnContainer {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.updateBtn {
    width: 50%;
    max-width: 450px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

@media screen and (max-width: 599px) {
    .bankDetailsContainer {
        max-width: 80% !important;
    }
    .formTitle {
        font-size: 24px !important;
    }
}
