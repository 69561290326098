$icon_color: #808080;
$font_color: #43425d;

.topbar_content {
    padding: 10px;
    cursor: pointer;

    &__text {
        display: inline;
        color: $font_color;
        font-weight: 700 !important;
    }
    &__icon {
        font-size: 18px !important;
        color: $icon_color;
    }
    &:active {
        transform: translateY(1px);
    }
}
