.wrapCircle {
    font-weight: bold;
    display: flex;
    width: 35px;
    height: 17px;
    justify-content: center;
    justify-items: baseline;
    align-items: center;
    cursor: pointer;
}

.itemTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.1rem 1.9rem;
}
