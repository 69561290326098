.container {
    height: 100%;
}

.merchantListContainer {
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.merchantDashBoardMenu {
    margin-top: 30px !important;
}
.pageContainer {
    margin-top: 30px !important;
}

.paymentsContainer {
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.backbtn {
    margin-bottom: 14px !important;
}
.backbtnContainer {
    margin-top: -20px !important;
}
