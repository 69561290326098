.listChargeback {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px !important;
}
.paper {
    overflow-x: auto !important;
}
.paper {
    overflow-x: auto !important;
}
.header {
    background-color: rgb(238, 238, 238);
    z-index: 0;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    text-align: left !important;
}

.mainContainer {
    margin-top: 30px;
    padding: 0 !important;
    border-top: 1px #b0b0b0 solid;
}

.chargebackNone {
    text-align: center !important;
    padding: 10% 0 !important;
}

.label {
    position: absolute !important;
    left: 20px;
    background-color: white;
}

.TableContainer {
    margin-top: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}
.processRefundButton {
    text-transform: none;
    width: 115px;
}
.searchMerchants {
    margin-left: 10px;
    background-color: white;
    width: 62%;
    margin-right: 5px !important;
    label {
        z-index: 0;
    }
}

.searchArea {
    margin: 40px 30px;
    display: flex !important;
    justify-content: flex-start !important;
}

.title {
    font-size: 21px;
    font-weight: bold;
}

@media screen and(max-width: 900px) {
    .searchArea {
        margin: 40px 20px;
    }
    .searchMerchants {
        width: 15rem !important;
    }
    .searchButton {
        margin-left: 0px !important;
    }
}
@media screen and (max-width: 500px) {
    .searchArea {
        margin: 30px 10px;
        display: flex !important;
        flex-direction: column !important;
    }
    .searchMerchants {
        width: 70vw !important;
    }
    .searchDate {
        margin-top: 20px !important;
    }
    .searchButton {
        margin-left: 0px !important;
        width: 100px !important;
    }
    .listChargeback {
        max-width: 100vw !important;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 20px !important;
    }
}
