.title {
    font-size: 25px;
    font-weight: bold;
}

.businessDetailsContainer {
    width: 73%;
    margin-left: 5px;
}

.disableFlex {
    flex: none !important;
}

.cardHeadertitleEdit {
    width: 350px;
}
