.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.customOutlineInput {
    border-radius: 4px;
    font-size: 16px;
    height: 35px;
    width: 100%;
}
