.header {
    background-color: rgb(238, 238, 238);
    z-index: 0;
}
.top {
    margin: 10px;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.tableRefundCell {
    max-width: 200px !important;
    min-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.row {
    z-index: 0 !important;
}
.mainContainer {
    margin-top: 30px;
    padding: 0 !important;
    border-top: 1px #b0b0b0 solid;
}
.calenderDiv {
    display: flex;
    width: 100%;
    color: #8783b5;
    z-index: 10;
}
.refundsNone {
    text-align: center;
    padding-bottom: 118px;
    padding-top: 118px;
}
.rangeDiv {
    text-align: center;
    width: 100%;
    margin: auto;
    user-select: none;
    padding: 5px 5px !important;
}
.rangeSelect {
    font-size: 16px !important;
    padding-right: 20px;
    text-align: right !important;
}
.paper {
    margin-top: 30px;
    margin-bottom: 100px;
}
.TableContainer {
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}
.navButton {
    margin: auto;
    text-transform: 'none';
}
.resendBtns {
    max-width: 160px;
    margin: 5px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.buttons {
    display: grid;
}

@media screen and (max-width: 1023px) {
    .buttons {
        display: block;
    }
}
