.primaryOwner {
    font-size: 1.5em;
    margin-left: 20px;
    font-weight: bold;
}

.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dateOfBirthWrapper {
    display: flex;
    flex-direction: row;
    :not(:last-child) {
        margin-right: 10px;
        padding-right: 0;
    }
}

.selectContainer {
    margin-top: 10px !important;
}

.ownershipLabel {
    margin-bottom: 8px !important;
}

.numberFormatInput {
    background-color: white;
    height: 34px;
}
.containsErrorDOB {
    border: 1px solid red !important;
    > fieldset {
        border: 1px solid red !important;
    }
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}
