.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.noData {
    text-align: center;
    padding-bottom: 118px;
    padding-top: 118px;
}

.TableContainer {
    margin-top: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}

.searchButton {
    margin-left: 15px !important;
}
.formControl {
    margin-left: 10px !important;
    padding-bottom: 25px !important;
}
.selectInput {
    background-color: white;
    min-width: 250px !important;
    margin-right: 10px !important;
    height: 40px;
}
.inputlabel {
    margin-bottom: 10px;
}

.searchMerchants {
    background-color: white;
    min-width: 300px !important;
    margin-right: 20px !important;
}
.search_form {
    margin: 5px;
}

@media screen and (max-width: 599px) {
    .searchMerchants {
        margin-bottom: 10px !important;
        margin-right: 10px !important;
    }

    .searchButton {
        margin-left: 0 !important;
    }
}

.contractTitle {
    font-size: 24px;
    font-weight: bold;
}
.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 140px;
    padding-top: 20px;
}
.container {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
