.modalConfirmation {
    .modalDialog {
        margin: 0;
        height: 200px;
        width: 400px;
        left: 50%;
        top: 50%;
        margin-top: -100px;
        margin-left: -200px;

        .modalHeader {
            text-align: center;

            h3 {
                width: 100%;
                margin: 0;
            }
        }

        .modalBody {
            padding: 0;

            .text {
                padding: 15px 30px;

                p {
                    margin: 0;
                }
            }
        }
    }
}
