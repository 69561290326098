.payAmount {
    color: #fff;
    background-color: #43425d;
    border: none;
    box-shadow: none !important;

    &:hover {
        background-color: #43425d;
    }

    &:disabled {
        color: #aaaaaa;
        background-color: #d8d9dc;
    }
}

@media screen and (max-width: 540px) {
    .cardForm {
        flex-direction: column;
    }
}

@media screen and (max-width: 499px) {
    .cardForm {
        flex-direction: column;
    }
}
