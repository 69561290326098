.paymentsNone {
    padding: 32px 16px;
    text-align: center;
}
.resultSpan {
    margin-left: 10px;
    padding-bottom: 10px;
}
.currencySpan {
    padding-left: 5px;
    color: gray;
}

.tableRow > * {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    overflow-wrap: break-word;
}

@media screen and (max-width: 599px) {
    .tableRow > * {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .dateColumn {
        max-width: 100px;
    }
}
.deleteIcon {
    cursor: pointer;
}
