.signUpForm {
    position: absolute;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 200px;
}

@media screen and (max-width: 600px) {
    .signUpForm {
        top: 170px;
    }
}

@media screen and (max-width: 600px) {
    .signUpForm {
        width: 300px;
        margin-left: -150px;
    }
}

@media screen and (max-width: 400px) {
    .signUpForm {
        width: 240px;
        margin-left: -120px;
    }
}

.gridContainer {
    margin-bottom: 10px !important;
}

.signUpFormButton {
    text-align: center;
    margin-bottom: 10px;
    button {
        width: 100%;
    }
}

.loading {
    margin-bottom: 16px;
    text-align: center;
}

.userName {
    margin-bottom: 10px;
}
.signUpButton {
    background-color: green !important;
    color: white !important;
}
.signUpButtonDisabled {
    background-color: #dcdcdc;
}

.linkToSignUp {
    text-align: center;
    font-size: 17px;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .linkToSignUp {
        font-size: 17px;
    }
}

.socialAccountLink {
    font-size: 20px;
}

.phoneInput {
    width: 100% !important;
    background-color: #dcdcdc;
    &:focus {
        outline: 0;
        box-shadow: none !important;
        border: 1px solid #2c7be5 !important;
    }
}
.crossIcon {
    color: red;
}

.validationText {
    padding-top: 3px;
}

.validationTextContainer {
    padding-left: 10px !important;
}

.validaion__messages {
    margin-bottom: 10px;
}

.formControl {
    background-color: #dcdcdc !important;
}

.expiryPageContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.leftContainer {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8fbf9;
    height: 100%;
}

.leftContainer > img {
    width: 40%;
    height: 25%;
}

.rightContainer {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.rightContainerData {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.rightContainerData > h4 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin-top: 30px;
}

.rightContainerData > h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
}

.rightContainerData > h6 > span > a {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 19.2px;
    text-align: center;
    color: #005896;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #f9ecea;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle img {
    width: 75%;
    height: 75%;
    object-fit: cover;
}

.footer {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer > h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: #005896;
    cursor: pointer;
}

.footer > h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #878da3;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.homeLogo {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 170px;
    }
}

.getOtpButton {
    display: flex;
    flex-direction: row;
}
.flexContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.phoneInput {
    flex: 1;
}

.otpButtonDisabled {
    width: 121px;
    height: 34px;
    background-color: #dcdcdc;
}
.otpButtonEnabled {
    width: 121px;
    height: 34px;
    background-color: #43425b !important;
    color: #ffffff !important;
}
.otpTxt {
    color: #f50100;
    font-size: 14px;
}

@media screen and (max-width: 600px) {
    .homeLogo {
        height: 170px;
        img {
            width: 130px;
        }
    }
}
