.containerDiv {
    display: flex;
    margin-top: 10px;
    padding: 0px 15px;
    border-bottom: 1px #ddd solid;
}

.headerDetails {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.content {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    font-weight: bold;
}
.contentBlock {
    padding: 10px 0px;
}
.pageTitle {
    font-size: 16px;
    font-weight: bold;
}
.spanRefId {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: gray;
}

.amount_items {
    margin-right: 10px;
    font-size: 24px;
    font-weight: bold;
}
.spnaCurrency {
    font-size: 12px;
    padding-left: 5px;
    font-weight: lighter;
    color: gray;
}
.spanStatus {
    font-size: 14px;
    margin-left: 15px;
}
.timelineDiv {
    display: flex;
    padding: 15px;
}
.timeline {
    margin-left: 8px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding-bottom: 10px;
}
.paymentDetailsDiv {
    display: flex;
    padding: 15px;
}

.tableHeader {
    background-color: #fafafa !important;
}
.graySpan {
    color: gray;
    font-weight: 500;
}
.copyIcon {
    cursor: pointer;
}

.overflowX {
    overflow-x: auto;
}

@media screen and (max-width: 599px) {
    .responsiveDiv {
        display: flex;
        width: 100% !important;
        padding: 15px;
    }
}
