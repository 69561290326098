.buttonContainer {
    display: inline-block;
    width: 100%;
    color: #8783b5;
}

.searchBtn {
    padding: 3px 8px 3px 8px;
    margin-left: 4% !important;
}
.container {
    margin-top: 20px;
    width: 95% !important;
}
.merchantData {
    margin-left: 4%;
}
.onBoardMerchantButton {
    display: flex;
    justify-content: flex-end;
}

.TablesPaper {
    max-height: 400px;
    margin-top: 20px;
    text-align: center !important;
}
.merchantLabelText {
    margin-top: 3px !important;
    width: 120px;
    font-weight: bold !important;
}
.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    margin-left: 10px !important;
    color: #005896;
}
.icon {
    &:not([disabled]) {
        color: #005896 !important ;
    }
}
.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    margin-left: 2%;
}

.merchantBlock {
    padding: 20px 0;
    border-bottom: 1px solid #e8e9ec;
}
.merchantName {
    font-size: 30px;
    font-weight: bold;
    margin-right: 5px;
}
.verifyBadge {
    vertical-align: text-bottom;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
}
.overFlowText {
    cursor: pointer;
}
.overflowSpan {
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    min-width: 180px !important;
    max-width: 180px !important;
}
.detailsView {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.reqBtn {
    padding: 10px 15px !important;
    border-radius: 10px !important;
    background-color: #005896 !important;
    color: #e8e9ec !important;
    text-transform: none !important;
}
.thirdId {
    margin-bottom: 10px !important;
    display: flex;
}
.tabView {
    margin-top: 50px !important;
}
.mainContainer {
    margin: 20px auto 0;
    border-radius: 10px !important;
    width: 90%;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.subTitle {
    font-size: 16px;
    font-weight: bold;
}
.divSubTitle {
    padding: 16px 16px 0px;
}

.secondaryActionBtn {
    width: 48%;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}
.closeIcons {
    margin-left: auto;
    margin-right: 2px;
}
.paper {
    width: 60%;
    max-width: 600px !important;
    min-width: 250px !important;
}
.listItemContainer {
    background-color: white;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    justify-content: center;
}
.bannerMessage {
    font-size: 15px;
    justify-content: center;
    padding: 10px;
}

.click__here {
    color: #e37372;
    font-weight: bold;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
    &:hover {
        text-decoration: underline;
    }
}
.automaticPayout {
    background-color: #d3eff5;
    border: 2px solid #d3eff5;
    margin-bottom: 7px;
    border-radius: 5px;
}

.primaryActionBtn {
    width: 48%;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.searchBar {
    width: 65% !important;
    max-width: 100% !important;
}
.tableCellSmallSize {
    margin-left: 10% !important;
}

.searchGrid {
    width: 90% !important;
    margin: 0 auto;
    margin-top: 2%;
    padding-top: 20px;
}

.mainForm {
    display: flex;
    align-items: baseline;
}
@media screen and (min-width: 1300px) {
    .listItemContainer {
        margin-left: -10%;
    }
}
@media screen and (max-width: 749px) {
    .searchBar {
        width: 60% !important;
        max-width: 100% !important;
    }
    .btnContainer {
        min-width: 100%;

        justify-content: center;
        align-items: center;
    }
    .leftAlign {
        margin-top: 10% !important;
        margin-bottom: 2%;
        margin-right: 2%;
    }
    .paper {
        width: 80%;
        max-width: 500px !important;
        min-width: 250px !important;
    }
}
@media screen and (max-width: 640px) {
    .leftAlign {
        justify-content: space-between;
        padding: 0 20px;
        margin-left: 0 !important;
        width: 100% !important;
        flex-direction: column;
        align-items: flex-start !important;
    }
    .searchBar {
        width: 57% !important;
        margin-bottom: 10px !important;
    }
    .searchBtn {
        margin-left: 20px !important;
    }
    .onBoardMerchantButton button {
        font-size: 12px;
    }
    .detailsView {
        flex-direction: column;
    }
    .reqBtn {
        width: -webkit-fill-available;
        align-items: center;
        margin-left: 5% !important;
        margin-top: 5% !important;
    }
}

.tableContainer {
    border: 1px solid #e8e9ec;
    border-radius: 4px !important;
    box-shadow: none;
    width: 100% !important;
}

.paper {
    width: 100%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.dialogBtn {
    width: 90px;
}
.leaveBtn {
    width: 90px;
    background-color: #005896;
}

.dialogContent {
    width: 400px !important;
}
.dialogText {
    display: flex;
}

@media screen and (max-width: 599px) {
    .dialogContent {
        width: 100% !important;
    }
}
