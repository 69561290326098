.paper {
    width: 100%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.dialogBtn {
    width: 90px;
}
.leaveBtn {
    width: auto !important;
    &:not([disabled]) {
        background-color: #e36985 !important;
        color: #ffffff !important;
    }
}

.dialogContent {
    width: 400px !important;
}
.dialogText {
    display: flex;
}

@media screen and (max-width: 749px) {
    .paper {
        width: 80%;
        max-width: 500px !important;
        min-width: 250px !important;
    }
}

@media screen and (max-width: 599px) {
    .dialogContent {
        width: 100% !important;
    }
}
