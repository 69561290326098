.containerDiv {
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.payoutAmt {
    font-weight: bold !important;
    font-size: 30px !important;
}
.transLabel {
    margin-left: 10px;
}

.payout {
    color: #a0aac1;
    display: flex;
}
.sectionContainer {
    margin-top: 50px !important;
    display: flex;
    flex-direction: column;
}
.sectionTitle {
    margin-bottom: 16px;
}
.sectionFields {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.attribute {
    color: #8782b4;
    padding: 5px;
    font-size: 14px !important;
}
.attributedata {
    padding: 5px;
    font-size: 14px !important;
}
.date_expected {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    color: gray;
}
.headerDetails {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.attributeValues {
    margin-left: 70px !important;
}
.accountattributeValues {
    margin-left: 200px !important;
}
@media only screen and (max-width: 700px) {
    .attributeValues {
        margin-left: 20px !important;
    }
    .accountattributeValues {
        margin-left: 85px !important;
    }
}

.containerSectionDiv {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    border-bottom: 1px solid #ddd;
}
.containerDetailsDiv {
    padding: 15px !important;
}

.title {
    font-size: 16px !important;
    font-weight: 700 !important;
}
