.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}
.customInput {
    width: 100%;
}

.inputLookupCompany {
    background-color: white;
}

.withoutBorders {
    border: 1px solid #fcfdfe;
    border-radius: 5px;
    padding: 5px;
}

.companyContainer {
    align-items: center;
}

.lookupCompanyButton {
    width: 100%;
    height: 40px;
}

.textButton {
    line-height: 18px;
}
.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
}
.errorIcon {
    font-size: 40px !important;
    color: #f44336 !important;
}

.validIcon {
    color: #00cc00;
    width: 50px !important;
    height: 50px !important;
}
.notificationContainer {
    margin-top: 20px;
    border-radius: 15px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.iconUnverified {
    margin: 0 20px 0px 30px;
    font-size: 8px !important;
    color: #f44336 !important;
}
.ibannotificationContainer {
    border-radius: 15px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 0px 0;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}
