.sectionTitle {
    font-size: 16px;
    font-weight: bold;
}

.overflowX {
    overflow-x: auto;
}

.summaryDivContainer {
    display: flex;
    margin-top: 30px;
}

.transactionsDivContainer {
    display: flex;
    margin-top: 30px;
}

.totalPayouts {
    font-weight: 500;
}

.transactionsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exportButton {
    margin-left: 2px;
}

.alignLeft {
    text-align: right !important;
}

.transactionType {
    text-transform: capitalize;
}
.divTableFooter {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 16px;
}

.amountSpan {
    padding-left: 3px;

    font-weight: bold;
}
.columnSpan {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87) !important;
}
