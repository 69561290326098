.dialogContentDatePicker {
    width: 165px !important;
    margin-top: -0.5% !important;
    margin-right: 3% !important;
}

.dialog {
    width: 100% !important;
}

.dialogContent {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 23px !important;
}
.datePicker {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 23px !important;
    > div {
        display: flex;
        gap: 20px;
    }
}

.exportSelect {
    height: 40px !important;
    width: 157px !important;
    background-color: transparent !important;
    > div {
        background-color: transparent !important;
    }
}
.selectTimeFrame {
    margin-top: 10px !important;

    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    gap: 25px !important;
}

.dialogActions {
    margin-top: 20px !important;
}

@media (max-width: 600px) {
    .MuiOutlinedInput-input {
        font-size: 14px;
        padding: 6px;
    }
}

@media screen and (max-width: 519px) {
    .datePicker {
        > div {
            display: flex;
            gap: 25px;
        }
        width: 100% !important;
        margin-top: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
    }
    .paraText {
        width: 2.5rem !important;
    }
}
