.qrIcon {
    margin-left: 0 auto !important;
}
.qrId {
    margin-left: 10px !important;
}
.transactions {
    display: flex;
    justify-content: space-around;
    font-size: 0.97rem !important;
    background-color: #f8faff;
    padding-top: 22px;
    padding-bottom: 12px;
}
.divTitle {
    padding: 16px;
    text-align: center;
}
.downloadButton {
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}
.icon {
    font-size: 'small' !important;
}
.header {
    margin-top: 5px;
}
.closeIcon {
    float: right;
    text-align: center;
    cursor: pointer;
    margin-top: -55px;
    color: #777;
    &:hover {
        background-color: #f6f7fa;
        border-radius: 1px;
    }
}
.verticalLine {
    align-self: center;
    color: #eaedef !important;
    font-size: 25px;
}
.tableBorder,
.tableBorder td {
    border: none;
    padding: 7px !important;
    font-size: 0.9rem !important;
    color: #8991ae;
}

.boxView {
    display: flex;
    justify-content: space-around;
    font-size: 0.97rem !important;
    margin-top: 3px !important;
    border: 1px solid rgb(217, 217, 219);
    color: rgb(35, 147, 240);
}

.payments {
    display: flex;
    justify-content: space-between;
    font-size: 0.97rem !important;
    margin-top: 3px !important;
    color: rgb(35, 147, 240);
}
.fontColor {
    color: #777;
}
.tableBody {
    margin-top: 15px;
}
.values {
    color: #12263f;
}
.reason {
    color: #12263f;
    word-break: break-word;
}
.link {
    background-color: none !important;
    color: #12263f;
}
@media screen and (max-width: 360px) {
    .divTitle {
        padding: 15px 5px;
    }
}
