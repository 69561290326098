.table {
    padding-top: 30px;
    margin: 0 7%;

    .pageTitle {
        box-shadow: 0px 0px 3px 1px gray;
        margin-bottom: 20px;
        padding: 10px 0 15px 0;
        text-align: center;

        .textHeader {
            margin: 0;
        }
    }
}

.pageTitle {
    text-align: center;
}
