.lookupCompanyButton {
    width: 100%;
    height: 37px;
    text-transform: none !important;
    font-weight: 300 !important;
}

.errorSpan {
    color: rgba(0, 0, 0, 0.54);
}

.textButton {
    font-size: 13px;
}
