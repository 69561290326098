.container {
    &_title {
        font-weight: bold !important;
        font-size: 21px !important;
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }
}
.table {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 15px;
}
.navContainer {
    h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
}
.buttonColor {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.createbtn {
    width: 80%;
    max-width: 100%;
    margin-top: 8px !important;
    height: 40px;
}

.settingsContainer {
    margin-top: 30px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.mainContainer {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 30px !important;
    margin-top: 0 !important;
}
.linkEllipse {
    cursor: pointer !important;
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    max-width: 200px;
    padding-top: 12px;
}
.textInput {
    cursor: pointer !important;
    color: rgb(25, 100, 238) !important;
}

.inputlabel {
    margin-bottom: 100px;
}
.selectInput {
    background-color: white;
    min-width: 200px !important;
    margin-right: 10px !important;
    height: 40px;
}

.customInput {
    border-radius: 4px;
    font-size: 16px;
    height: 35px;
    width: 80%;
    max-width: 100% !important;
    margin-top: 20px;
}
.select {
    margin-top: 10% !important;
}
.filterDiv {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
}
.filterContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 31px;
    height: 38px !important;
    button {
        margin-right: 10px;
    }
}
.createQrContainer {
    margin-bottom: 20px;
    box-shadow: inset 0 -1px #e3e8ee;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
}

.shortText {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
}

.qrCode {
    display: flex !important;
    width: 100%;
    padding: 10px 20px 20px 20px;
    margin-top: 10px;
    justify-content: space-between !important;
}
.pageTitle {
    padding-left: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    margin-left: -14px;
}

.customInput fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px !important;
}
.qrfieldStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.qrTitle {
    font-size: 21px;
    font-weight: bold;
}

.outline {
    width: 100% !important;
    border: 1px solid #e8e9ec;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
    margin-top: 5%;
}
.selctbtn {
    width: 80%;
    max-width: 100%;
    margin-top: 8px;
    height: 40px;
}
.qrIdInput {
    margin-top: 8px !important;
    width: 80%;
}
.mobileQrSelctor {
    margin: 0 auto;
    width: 50%;
    background-color: #fff;
}
.mobileQrSelctor div:first-child {
    background: #fff !important;
}
.selctbtn div:first-child {
    background: #fff !important;
}
.createbtn div:first-child {
    background: #fff !important;
}
.expiredStatus {
    background-color: #f05150;
    color: '#e67e00';
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    width: 70%;
    text-align: center !important;
}
.activeStatus {
    background-color: #caf5c9;
    color: '#e67e00';
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    width: 70%;
    text-align: center !important;
}
.loading {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 100px;
}
.slide-pane__overlay.overlay-after-open {
    background-color: rgba(240, 248, 255, 0);
}
.createQrBtn {
    // margin-top: 33px !important;
    padding: 7px 16px !important;
}
@media screen and (max-width: 640px) {
    .outline {
        width: 100% !important;
    }
    .mainContainer {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }
    .createbtn {
        position: fixed;
        bottom: 0px;
        left: 0;
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        display: block;
        right: 0;
        background-color: rgb(8, 108, 202);
        justify-content: center;
        padding: 10px;
    }
    .createQrmobile {
        box-shadow: 0px -1px 6px 1px #ddd;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        background: #f7f7f7;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .mobilenavContainer {
        display: flex;
        justify-content: center;
    }
    .filterBtn {
        width: 100%;
        max-width: 400px;
        display: flex;
    }
}
@media screen and (max-width: 599px) {
    .copyIcon {
        text-align: center;
        margin-left: 0;
    }
}
