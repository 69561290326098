.paper {
    width: 50% !important;
    max-height: fit-content;
    height: 80%;
}

.dialogContent {
    padding: 0px !important;
}
.searchButton {
    height: 40px;
}
.searchGrid {
    padding: 30px !important;
}
.searchMerchants {
    background-color: white;
    width: 60%;
    margin-right: 20px !important;
}

.startPdqButton {
    background-color: green !important;
    color: white !important;
}
.textInput {
    cursor: pointer !important;
    color: #005896 !important;
}
.accessButton {
    background-color: #005896 !important;
    color: #f8fbfd !important;
}
.closeIcon {
    margin-left: 180%;
    margin-bottom: 20%;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
        border-radius: 1px;
    }
}
.rowData > * > span {
    cursor: default;
}
.rowData > * > span:hover {
    background-color: white !important;
}
.nameColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0%;
    min-width: 195px;
    max-width: 195px;
}
.modalSearchButton {
    background-color: #005896 !important;
    color: #f8fbfd !important;
    text-transform: none !important;
}

@media screen and (max-width: 960px) {
    .searchMerchants {
        width: 100%;
        margin-bottom: 10px !important;
    }
}

@media screen and (max-width: 600px) {
    .searchMerchants {
        background-color: white;
        width: 100%;
    }
    .mainData {
        padding: 0px !important;
    }
    .modalSearchButton {
        width: 20%;
    }
    .nameColumn {
        padding: 0%;
    }
    .paper {
        width: 100% !important;
        height: 70% !important;
        max-height: fit-content;
    }
}
@media screen and (max-width: 400px) {
    .modalSearchButton {
        width: 5%;
    }
}
