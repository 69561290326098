.container {
    padding: 0 3% 0px 0%;
    overflow: auto;
    margin-bottom: 10% !important;
}

.searchPaper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 30px 0;
}

.searchPaper > form {
    display: inline-block;
}
.logo {
    float: right;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.nameLink {
    color: #005896 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    &:hover {
        color: #212529 !important;
    }
}

.nameColumn {
    max-width: 210px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 0;
}
.midColumn {
    width: 13%;
}
.dateColumn {
    width: 25%;
}
.resellerLabel {
    display: inline-block;
    margin-left: 5px !important;
    color: #212529 !important;
    font-weight: 900 !important;
    font-size: 25px !important;
}
.resellerIdField {
    margin-left: 20px !important;
    background-color: #e0e0e0;
}
.searchMerchants {
    background-color: white;
    width: 60%;
    margin-right: 20px !important;
}

.newMerchantbutton {
    height: 100%;
    width: 90%;
    background-color: #005896 !important;
    color: #f8fbfd !important;
    text-transform: none !important;
}
.selectInput {
    background-color: white;
    min-width: 290px !important;
    margin-right: 10px !important;
    height: 40px;
    width: 100%;
}
.formControl {
    margin-left: 5px !important;
}
.searchAllButton {
    margin-bottom: 10%;
}

.doneIconStyle {
    color: green;
}

.logoImage {
    display: flex;
    justify-content: center;
}

.addressColumn {
    max-width: 200px !important;
    min-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.searchContainer {
    width: 100%;
    max-width: 50px;
    text-align: end;
}
.searchButton {
    height: 40px;
    background-color: #005896 !important;
    color: #f8fbfd !important;
    text-transform: none !important;
}
.resellerId {
    margin-bottom: 20px;
    margin-top: 50px;
}
.resellerHeader {
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 50px !important;
    margin-top: 30px;
    overflow: hidden;
}
.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    color: #005896 !important;
    margin-top: 7px;
}
.textInput {
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    min-width: 105px !important;
    max-width: 105px !important;
}
.emailCell {
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    min-width: 180px !important;
    max-width: 180px !important;
}
.applicationDiv {
    display: flex !important;
    margin-top: 12px;
}
.applicationId {
    display: flex !important;
    max-width: 125px;
}

.logout {
    cursor: pointer;
    line-height: 30px;
}
.menuText {
    display: inline-block;
    margin-left: 5px !important;
    color: #212529 !important;
    font-weight: 500 !important;
}
.loading {
    text-align: center;
    margin-top: 100px;
}
.accessNameButton {
    text-transform: none;
}
.header {
    background-color: rgb(238, 238, 238);
}
.rowData > * > span {
    cursor: default;
}
.rowData > * > span:hover {
    background-color: white !important;
}
.accessButton:hover {
    background-color: #43425d !important;
    color: white;
}
.TableContainer {
    overflow-x: initial !important;
}
.radioButton {
    padding: 0px !important;
}
.pageTitle {
    font-weight: 700;
    font-size: 20px;
    color: #43425d;
}
.flexDiv {
    display: flex;
    padding-top: 25px;
}
.filterreseller {
    margin-left: -50px !important;
}

.merchantName {
    transition: background-color 0.5s;
    background-color: transparent;
    user-select: none;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
}

.label {
    position: absolute !important;
    left: 20px;
    background-color: white;
}

.labelText {
    font-size: 15px !important;
}
.clearFilter {
    color: #005896;
    cursor: pointer !important;
}

.listItemContainer {
    background-color: #f8fbfd;
    margin-top: 20px;
    height: auto;
    min-height: 50px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}

.iconVerified {
    margin: 0 15px 5px 30px;
}
@media screen and (max-width: 959px) {
    .resellerId {
        margin-top: 10px;
    }
}
@media screen and (max-width: 600px) {
    .searchContainer {
        text-align: start;
    }
    .newMerchantbutton {
        margin-top: 15px !important;
        width: 90% !important;
    }
    .formControl {
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 480px) {
    .resellerHeader {
        width: 300px !important;
        margin: 0 auto !important;
    }
    .selectInput {
        min-width: 263px !important;
    }
    .searchButton {
        margin-top: 10px !important;
    }
    .searchMerchants {
        width: 100%;
    }
    .newMerchantbutton {
        width: 100% !important;
    }
}
@media screen and (max-width: 320px) {
    .selectInput {
        width: 250px;
    }
}
