.checkListContainer {
    padding: 16px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.actionButtons {
    display: flex;
    justify-content: center;
}

.buttonStyle {
    margin: 20px !important;
}

.checkListItem {
    padding-right: 115px !important;
}

.closeButton {
    margin-left: 65px !important;
    margin-top: -50px !important;
}

.sendNotesContainer {
    padding: 5px;
    margin: 0px auto 20px auto;
}

.sendNotesButton {
    padding: 5px;
    margin: 20px auto 0 auto !important;
    min-width: 200px !important;
    width: 32%;
}

.sendNotesError {
    padding: 5px;
    margin: 5px auto 0 auto;
    width: 100%;
    justify-content: center;
}

@media (min-width: 1280px) {
    .sendNotesContainer {
        flex-grow: 0 !important;
        max-width: 66.666667% !important;
        flex-basis: 66.666667% !important;
    }
}
