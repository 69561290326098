.container {
    margin: 30px 5% 100px 5%;
}

.backButtonBox {
    display: inline-block;
}

.merchantName {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.loading {
    text-align: center;
}

.detailsView {
    margin-top: 30px;
}

.thirdId {
    margin-top: 30px !important;
}
.mainContainer {
    margin-top: 30px;
    border-radius: 10px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.subTitle {
    font-size: 16px;
    font-weight: bold;
}
.divSubTitle {
    padding: 16px 16px 0px;
}

.viewDocuments {
    margin-bottom: 20px !important;
}

.paymentsContainer {
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    min-height: 350px;
}
