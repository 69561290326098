.selectTermsAndConditionInput {
    margin-top: 10px;
    border: 1px solid #e8e9ec !important;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px !important;
}

.termsAndConditionHeader {
    font-size: 20px !important;
    font-weight: bold !important;
}

.uploadTermsAndConditionContainer {
    width: 1000px;
    padding: 30px;
    margin-bottom: 20px;
}

.selectTermsAndConditionInput:hover {
    outline: 0;
    box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
    border-color: #43425d !important;
}

.pdfIcon {
    margin-right: 10px;
    color: #43425d !important;
}

.tableContainer {
    margin-top: 15px !important;
    margin-bottom: 30px !important;
}

.buttonContainer {
    margin-right: 25px;
}

.disableInputClass {
    pointer-events: none;
    opacity: 0.4;
}

.noFiles {
    text-align: center;
    padding: 40px;
}

.allowedFormateStyle {
    margin-top: 10px !important;
    font-size: 15px !important;
}
