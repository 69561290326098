.buttonsWrapper {
    display: flex;
    justify-content: space-between;
}

.saveNextBtn,
.saveExitBtn {
    flex-basis: 49%;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    text-transform: none !important;
    font-weight: 300 !important;
}

.primaryButtonColor {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.nextBtn {
    width: 100%;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    text-transform: none !important;
    font-weight: 300 !important;
}

.remindMeLater {
    text-decoration: underline;
    font-size: 14px;
    color: grey;
    cursor: pointer;
    margin-bottom: 20px;
}

@media screen and (max-width: 599px) {
    .pendingButton {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .firstButton {
        margin-top: 20px !important;
    }
    .nextBtn {
        margin-top: 20px !important;
        margin-bottom: 0 !important;
    }
    .buttonsWrapper {
        display: block;
    }
    .saveNextBtn,
    .saveExitBtn {
        width: 100%;
        margin-top: 10px !important;
        margin-bottom: 5px !important;
    }
}
