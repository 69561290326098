.validationNote {
    padding-top: 60px;
}

@media screen and (max-width: 599px) {
    .validationNote {
        padding-top: 20px;
        margin-bottom: 40px;
        justify-content: center;
    }
}

.doneIcon {
    color: green;
}

.bulletIcon {
    color: #222222;
}
.crossIcon {
    color: red;
}

.validationText {
    padding-top: 3px;
}

.validationTextContainer {
    padding-left: 10px !important;
}
