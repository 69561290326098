.requestAmountButton {
    color: #fff;
    background-color: #43425d;
    border: none;

    &:hover {
        background-color: #43425d;
    }

    &:disabled {
        color: #aaaaaa;
        background-color: #d8d9dc;
    }
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    height: 34px;

    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

.note {
    color: #ff0000c7;
    font-weight: 200;
    font-size: x-small;
    margin-bottom: 0;
}
.heading {
    font-size: larger;
    font-weight: 600;
}

.quantityInput {
    width: 100% !important;
    height: 37px;
}
