.tabsDiv {
    color: #8783b5;
    font-size: 10px;
    padding: 0px;
    border-bottom: 1px solid #e8e9ec;
}
.paymentsNone {
    text-align: center;
    padding-bottom: 118px;
    padding-top: 118px;
}
.filterLink {
    color: #005896 !important;
    cursor: pointer;
    text-decoration: underline !important;
    margin-left: 5px !important;
}
.resultSpan {
    margin-left: 10px;
    padding-bottom: 10px;
}
.currencySpan {
    // padding-left: 5px;
    color: gray;
}
.paragraphCurrency {
    padding-left: 5px;
    color: gray;
    display: inline-block;
    margin: 0;
}
.refundDescription {
    color: gray;
}
.nameAddressColumn {
    cursor: pointer;
}
.dateColumn {
    cursor: pointer;
}
.refundColumn {
    cursor: pointer;
}
.status {
    cursor: pointer;
}
.tableRow > * {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    overflow-wrap: break-word;
}

.amountColumn {
    cursor: pointer;
}

.actionsColumn {
    max-width: 10px !important;
    width: 10px;
    cursor: pointer;
}

@media screen and (max-width: 599px) {
    .tableRow > * {
        padding-left: 5px !important;
        padding-right: 5px !important;
        border-bottom: none !important;
        border-top: 1px solid #ddd !important;
    }
    .mobileViewTransactionDetails td {
        border-bottom: none !important;
    }
    .amountColumn {
        max-width: 100px;
    }
    .mobileViewTransactionDetails > * {
        padding-left: 6px !important;
    }
}

.nameAddressColumn {
    max-width: 300px;
    // cursor : pointer
}
