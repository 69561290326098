.container {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    border-radius: 4px;
}

@media screen and (max-width: 599px) {
    .container {
        width: 100% !important;
    }
}

.documents {
    margin-top: 30px;
}

.subHeaderBackground {
    background-color: #f5f9fb !important;
    padding: 20px !important;
}

.paddingHeader {
    padding-left: 16px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 21px;
    font-weight: bold;
    border-bottom: 1px solid #e8e9ec;
    margin-bottom: 0px !important;
}

.popupText {
    display: flex;
    align-items: baseline;
}

.docContainer {
    width: 90% !important;
    max-width: 900px;
    margin: 30px auto !important;
}
