.container {
    &__img {
        width: 60%;
        margin-top: 1em;
        margin-bottom: 4em;
    }

    &__datman__img {
        width: 20%;
        margin-top: 1em;
        margin-bottom: 4em;
    }
    &__text {
        margin-top: 3em !important;
        &__ruleTitle {
            margin-left: 0.25em !important;
        }
        &__rules {
            margin-left: 1em !important;
        }
        &__subRules {
            margin-left: 2em !important;
            &__cell {
                width: 200px;
            }
        }
        &__table {
            width: calc(100% - 2em) !important;
            margin-bottom: 2em;
        }
        &__rulesBullets {
            margin-left: 3em !important;
        }
    }
}

// .iframeStyle {
//     width: 85vw;
//     height: 100vh;
//     border: none;
//     overflow-y: scroll; /* Allows vertical scrolling */
//     -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile (iOS) */
//     display: block;
// }

.iframeContainer {
    width: 100vw;
    height: 100vh;
    overflow-y: auto; /* Enables vertical scrolling within this container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Full-size iframe within the container */
.iframeStyle {
    width: 100%;
    height: 100%;
    border: none;
}

@media screen and (max-width: 599px) {
    .container {
        &__datman__img {
            width: 35%;
        }
        &__text {
            &__subRules {
                &__cell {
                    width: unset;
                }
            }
        }
    }
}
