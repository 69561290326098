.urgentMessageBanner {
    width: 100%;
    min-height: 40px;
    background-color: #fdf2ee;
    text-align: center;
    padding: 7px 50px;
    font-size: 15px;
    border-bottom: 2px solid #deb4a9;
}
.datePickerContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    display: flex;
}
.startDatePick {
    width: 50%;
}
.endDateDatePick {
    margin-left: 20px;
    width: 50%;
}

@media screen and (max-width: 599px) {
    .urgentMessageBanner {
        padding: 5px 10px;
        font-size: 14px;
    }
}
@media screen and (min-width: 992px) {
    .urgentMessageBanner {
        padding-left: 30%;
    }
}

.subject {
    font-weight: bold;
}

.messageUrgentMessage {
    display: inline-block;
    margin-right: 20px;
}

.buttonsUrgentMessage {
    display: inline-block;
}

.deleteIcon {
    margin-left: 10px !important;
    cursor: pointer;
}
.cancelBtn {
    margin-right: 17px !important;
    cursor: pointer;
}

.actionsContainer {
    margin-bottom: 10px;
    margin-right: 10px;
}

.errorAlert {
    margin-top: 10px;
}

.formContainer {
    margin: 10px 0 !important;
}

.inputMessage {
    background-color: white;
    width: 100%;
}

.datePicker {
    display: inline;
    font-size: 14px !important;
    color: #43425d !important;
    width: 100%;
    max-width: max-content;
    padding-top: 1px !important;
    input {
        padding: 10px 19px;
    }
}
.datePickerWidth {
    width: 100%;
}
.buttonDisabled {
    color: gray;
    cursor: not-allowed;
}

@media screen and (min-width: 280px) and (max-width: 640px) {
    .datePickerContainer {
        flex-wrap: wrap;
        padding: 0 10px;
        margin-left: -15px !important;
        width: 100%;
    }
    .startDatePick {
        width: 100%;
    }
    .endDateDatePick {
        margin-top: 10px;
        width: 100%;
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 599px) {
    .datePickerWidth {
        max-width: 150px !important;
    }
}

@media screen and (min-width: 960px) and (max-width: 991px) {
    .urgentMessageBanner {
        padding-left: 25%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .datePicker {
        input {
            padding: 10px 19px;
            width: 160px;
        }
    }
}

@media screen and (min-width: 960px) and (max-width: 991px) {
    .urgentMessageBanner {
        padding-left: 25%;
    }
}
