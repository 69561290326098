.modalUserManagement {
    .modalDialog {
        width: 50%;
        top: 50px;

        .modalBody {
            padding: 20px 30px;

            .title {
                width: 100%;
                text-align: center;
                margin-bottom: 30px;

                h3 {
                    margin: 0;
                }
            }

            .assignOnlyRoleCheckbox {
                text-align: center;
            }

            .pointer {
                cursor: pointer;
            }
        }
    }
}
