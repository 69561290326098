.dialogContent {
    overflow-y: auto;
}
.dialog {
    width: 70vw !important;
}
.icon {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 50%;
    position: absolute;
    right: 5%;
}
.pricingMatrix {
    margin: 10% 0;
    > div > div {
        margin: auto;
    }
}
.Input {
    > input {
        padding: 10px;
    }
}
.inputDisable {
    background-color: gainsboro;
    > input {
        padding: 10px;
    }
}
.counterBtn {
    border-width: thin;
    width: -webkit-fill-available;
}
.sendBtn {
    &:not([disabled]) {
        background-color: #005896 !important;
        color: #e8e9ec !important;
    }
}
.dialogHeader {
    display: flex;
}
.daialogbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.phoneInput {
    width: inherit !important;
}
.emailField {
    > div {
        margin-top: 0px !important;
    }
}
.inputNumber {
    width: -webkit-fill-available;
    > input {
        padding: 10px;
    }
}

.error_icon {
    color: #f44336 !important;
    margin: 10px -5px !important;
    padding: 0;
}
.require {
    color: #f44336;
}
@media screen and (max-width: 599px) {
    .daialogbox {
        width: fit-content;
    }
}
