.paper {
    width: 100%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.loading {
    background-color: transparent;
    width: 400px !important;
}
.dialogBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.leaveBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}

.dialogContent {
    width: 400px !important;
}
.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px !important;
}

@media screen and (max-width: 599px) {
    .dialogContent {
        width: 100% !important;
    }
}
