.settingsContainer {
    margin-top: 30px;
    border-radius: 10px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.settingsTitle {
    font-size: 21px;
    font-weight: bold;
}
.container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 30px !important;
    margin-top: 0 !important;
}
.navContainer {
    width: fit-content;
    margin-top: 30px !important;
    margin-left: 30px !important;
    h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
}

.settings__buttons {
    transition: background-color 0.5s;
    background-color: transparent;
    user-select: none;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
    }
    a {
        color: #43425d;
    }
}

.notificationContainer {
    margin-top: 30px;
    border-radius: 10px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    background-color: #ffffff !important;
    height: 61px;
}

.closeAccountnotification {
    top: 8px;
    left: 8px;
    right: 8px;
    position: fixed;
    border-radius: 10px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1400;
}

.iconUnverified {
    margin: 0 20px 0 30px;
    font-size: 12px !important;
    color: #f44336 !important;
}

.errorIcon {
    font-size: 20px !important;
    color: #f44336 !important;
}

.errorMessage {
    font-size: 15px !important;
}

.success_icon {
    color: #4caf50 !important;
    width: 18px !important;
    height: 18px !important;
}

.notification {
    font-weight: bold !important;
    margin-left: 10px;
}
.mainContainer {
    padding: 0px !important;
}

@media screen and (max-width: 320px) {
    .success_icon {
        margin-left: 10px !important;
    }
}

@media screen and (min-width: 600px) {
    .closeAccountnotification {
        top: 24px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 600px) {
    .container {
        margin: 0 !important;
    }
    .navContainer {
        margin-left: 0 !important;
        h3 {
            padding-left: 16px !important;
        }
    }
    .listContainer {
        margin-left: 10px !important;
        margin-top: 2px !important;
    }
}
