.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.registerContainer {
    margin-top: 30px !important;
    border-radius: 10px !important;
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}

.registrationTitle {
    font-size: 24px;
    font-weight: bold;
}
.formContainer {
    width: 88%;
    margin-left: 10%;
    margin-top: 50px;
    max-width: 450px;
}
.configContainer {
    width: 88%;
    margin-left: 10%;
    margin-top: 63px;
    max-width: 450px;
}
.formTitle {
    font-weight: bold !important;
    font-size: 16px !important;
}

.btnContainer {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.submitBtn {
    width: 50%;
    max-width: 450px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.configTitle {
    font-weight: bold !important;
    font-size: 16px !important;
    padding-top: 20px !important;
}
.productBox {
    border: 1px solid #e8e9ec;
    height: 100%;
    border-radius: 10px !important;
    width: 100%;
}
.productsTitle {
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 16px;
}
.productsContainer {
    width: 80%;
    margin-left: 10%;
    margin-top: 50px;
    max-width: 550px;
}

.dropDown {
    margin-top: 20px;
    flex-grow: 1;
}
.PhoneInput {
    width: 100% !important;
}
.titleGrid {
    padding: 16px;
}
.listContainer {
    width: 92%;
    margin-left: 5%;
}
.codeInput {
    width: 100% !important;
    height: 30px;
    background-color: white !important;
    &__input {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
}
