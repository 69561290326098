.contractContainer {
    margin-top: 30px !important;
    border-radius: 10px !important;
    max-width: 100%;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    padding: 10px;
}
.contractTitle {
    font-size: 24px;
    font-weight: bold;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.specialRentContainer {
    width: 100%;
    border: 1px solid #e8e9ec;
    border-radius: 4px !important;
}
.specialRentTable {
    background-color: white;
    padding: 12px 0;
    padding-right: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #ddd;
}
.graphContainer {
    background-color: #fff !important;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    width: 100%;
    margin-top: 40px !important;
}
.title {
    margin-left: 16px;
    font-size: 20px;
    font-weight: bold;
    color: #43425d;
}
.rentTable {
    padding: 0px;
}
.contractRent {
    padding: 5px;
    height: 10% !important;
}
.specialRentTable {
    display: flex !important;
    justify-content: space-between !important;
}
.addSpecialRentBtn {
    &:not([disabled]) {
        background-color: #006501 !important;
        color: white !important;
    }
    text-transform: none !important;
    border: none !important;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
@media screen and (max-width: 599px) {
    .specialRentTable {
        direction: row;
    }
    .title {
        margin-left: 3%;
    }
}
