.loginForm {
    position: absolute;
    width: 300px;
    left: 50%;
    margin-left: -150px;
}

@media screen and (max-width: 400px) {
    .loginForm {
        width: 240px;
        margin-left: -120px;
    }
}

.loginFormButton {
    text-align: center;
    margin-bottom: 10px;
    button {
        width: 100%;
    }
}
.verifyBtn {
    background-color: green !important;
    color: white !important;
}

.verifyCodeBtn {
    text-align: center;
    margin-bottom: 10px;
    button {
        width: 100%;
    }
    flex: auto;
    margin-left: 15px;
}

.loading {
    margin-bottom: 16px;
    text-align: center;
}

.alertError {
    width: 100%;
    text-align: center;
}

.linkToSignUp {
    text-align: center;
    font-size: 17px;
    margin-top: 10px;
}

.loginButton {
    background-color: green !important;
    color: white !important;
}

@media screen and (max-width: 400px) {
    .linkToSignUp {
        font-size: 17px;
    }
}

.alert_message {
    display: flex;
    justify-content: space-around;
}

.alertResetPassword {
    text-decoration: underline;
    color: darkblue;
}

.passwordLessForm {
    display: flex;
    grid-column: 2;
}

.passwordLessInput {
    flex-grow: 1;
    margin-right: 5px;
}

.passwordLessButton {
    margin-top: 2px !important;
    background-color: green !important;
    color: white !important;
}

.headerInfo {
    position: relative;
    text-align: center;
}

.resend {
    flex-grow: 1;
}

.phoneInput {
    width: 100% !important;
    &:focus {
        outline: 0;
        box-shadow: none !important;
        border: 1px solid #2c7be5 !important;
    }
}

.orSpan {
    overflow: hidden;
    text-align: center;
}

.orSpan:before,
.orSpan:after {
    background-color: #000;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.orSpan:before {
    right: 2em;
    margin-left: -50%;
}

.orSpan:after {
    left: 2em;
    margin-right: -50%;
}
