.container {
    margin-top: 30px !important;
    width: 460px;
}

@media screen and (min-width: 960px) {
    .container {
        margin-top: 0 !important;
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

@media screen and (max-width: 959px) {
    .container {
        padding-left: 5% !important;
    }
}

@media screen and (max-width: 599px) {
    .container {
        width: 100%;
    }
}

.formsContainer {
    margin-top: 25px !important;
}

.formItem {
    margin-top: 25px !important;
}

.titleContainer {
    display: flex;
    flex-direction: column;
}

.btnContainer {
    margin-top: 15px !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
}
