.navigationBar {
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
}

.navigationItem {
    display: flex;
    margin-left: 5px;

    border-bottom: 3px solid currentColor;
    height: 35px;
}

.navigationText {
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}

.navigationSearchContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e8e9ec;
    height: 35px;
}

.searchColor {
    color: #bcbccb;
}
