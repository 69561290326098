.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}
