.btnContainer {
    display: flex;
    justify-content: right;
    width: 100%;
}
.qrBtn {
    width: 18%;
    margin-right: 15px !important;
}
.submitBtn {
    width: 18%;
    margin-right: -8px !important;
}
.reasonInput {
    width: 100%;
    padding: 5px;
}
