.google {
    background-color: #d6492f !important;
    color: white !important;
    margin-bottom: 10px !important;
    width: 100%;
    padding: 0 !important;
}

.fb {
    background-color: #3a559f !important;
    color: white !important;
    margin-bottom: 10px !important;
    width: 100%;
    padding: 6px !important;
}

.imageGoogle {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.imageFacebook {
    width: 22px;
    height: 22px;
    margin-right: 15px;
}

.orContainer {
    overflow: hidden;
    text-align: center;
}

.orContainer:before,
.orContainer:after {
    background-color: #000;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.orContainer:before {
    right: 2em;
    margin-left: -50%;
}

.orContainer:after {
    left: 2em;
    margin-right: -50%;
}

.buttonName {
    text-transform: none;
}
