.dialogWrapper {
    padding: 5px;
    &__dialogBody {
        background-color: #f9fbfd;
        &__titleBar {
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            &__merchantName {
                margin-right: 5px;
                font-size: 20px;
                margin-left: auto;
                &__link {
                    margin-left: 10px;
                    font-style: normal;
                    text-decoration: underline;
                    font-size: 18px;
                    border: 1px solid grey;
                    border-radius: 5px;
                    padding: 0 4px 0 4px;
                    color: #2c7be5 !important;
                    background-color: transparent;
                    cursor: pointer;
                }
            }
            &__fileName {
                font-size: 20px;
                padding: 0 5px 0 5px;
            }
            &__documentNumber {
                font-size: 20px;
                margin-left: 10px;
                margin-right: auto;
                text-align: center;
            }
        }
        &__imageWrapper {
            height: 600px;
            margin: 0 auto 5px auto;
            &__imageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #e2e0e0;
                height: 100%;
                &__imageDiv {
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &__text,
                &__loading {
                    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                    font-size: 24px;
                    text-align: center;
                }
                &__loading {
                    color: grey;
                    font-size: 20px;
                }
                &__iframe {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__navArrow {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                &__button {
                    height: 100%;
                }
            }
        }
        &__actionsWrapper {
            margin: auto;
            padding: 5px;
            margin-bottom: 5px;
            &__button {
                flex-basis: 32%;
            }
        }
        &__notesWrapper {
            padding: 5px;
            margin: 0 auto 5px auto;
            &__button {
                margin: 5px auto 0 auto !important;
                min-width: 200px !important;
                width: 32%;
            }
            &__errorMsg {
                margin: 5px auto 0 auto;
                width: 100%;
                justify-content: center;
            }
        }
    }
}

.imageStyle {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.accountPaper {
    padding: 20px !important;
    border-radius: 5px !important;
}
.errorMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.codeInput {
    input:disabled {
        background-color: gainsboro;
        color: rgba(0, 0, 0, 0.38);
    }
}

.submitButton {
    display: flex;
}

.cardNumber {
    display: flex;
    padding-left: 30px;
}
