.settingsContainer {
    margin-top: 30px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.settingsTitle {
    font-size: 21px;
    font-weight: bold;
}
.container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 30px !important;
    margin-top: 0 !important;
}
.navContainer {
    width: fit-content;
    margin-top: 30px !important;
    margin-left: 30px !important;
    h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
}

.settings__buttons {
    transition: background-color 0.5s;
    background-color: transparent;
    user-select: none;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
    }
    a {
        color: #43425d;
    }
}

.notificationContainer {
    margin-top: 30px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    background-color: #ffffff !important;
    height: 61px;
}

.success_icon {
    color: #4caf50 !important;
    width: 18px !important;
    height: 18px !important;
}

.notification {
    font-weight: bold !important;
    margin-left: 10px;
}
.mainContainer {
    padding: 0px !important;
}

@media screen and (max-width: 320px) {
    .success_icon {
        margin-left: 10px !important;
    }
}

.listItemStyle {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
