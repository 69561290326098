.dashboardContentContainer {
    margin-top: 30px !important;
}
.continueOnboardingContainer {
    background-color: white;
    padding-left: 2% !important;
    margin-top: 20px;
    height: auto;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}
.continueOnboarding {
    background-color: #d3eff5 !important;
}

.linkFont {
    color: #6e6ca8 !important;
    font-weight: bold;
    cursor: pointer;
}
@media screen and (max-width: 959px) {
    .dashboardContentContainer {
        margin-top: 0 !important;
    }
}

.graphContainer {
    margin-top: 20px !important;
    display: flex;
    flex-wrap: nowrap !important;
}

.rightCardContainer {
    width: 345px;
    margin-right: 20px;
}

.rightCardContainer > * {
    width: 100%;
    display: inline-block;
    height: 150px;
    margin-left: 20px;
    margin-bottom: 10px;
    line-height: 50px;
}

.click__here {
    color: #e37372;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.info_icon {
    font-size: 20px !important;
    color: gray;
}

.error_icon {
    font-size: 20px !important;
    color: #f44336 !important;
}
.done_icon {
    color: green;
}

.success_icon {
    color: #4caf50 !important;
}

.listItemContainer {
    background-color: white;
    margin-top: 20px;
    height: auto;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}

.iconUnverified {
    margin: 0 16px 0 16px;
}
.iconVerified {
    margin: 0 15px 5px 30px;
}

.paymentsContainer {
    width: 100%;
    border: 1px solid #e8e9ec;
    border-radius: 4px !important;
}

@media screen and (max-width: 649px) {
    .graphContainer {
        flex-direction: column;
    }
    .paymentsContainer {
        width: 100%;
    }
    .rightCardContainer {
        width: 100%;
    }
    .rightCardContainer > * {
        width: 100%;
        height: 120px;
        margin-left: 0;
        margin-right: 0;
    }
}

.titlePaymentsTable {
    background-color: white;
    padding: 12px 0;
    padding-right: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #ddd;
}

.title {
    margin-left: 16px;
    font-size: 23px;
    font-weight: bold;
    color: #43425d;
}

.clickPayments {
    float: right;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.alert_background__color {
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
}
.automaticPayout {
    background-color: #d3fee5;
}

.automaticPayout {
    background-color: #d3eff5;
    border: 2px solid #d3eff5;
    margin-top: 7px;
    margin-bottom: 7px;
}

.notificationContainer {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
    padding: 5px 0;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    min-height: 50px;
}

.bannerStyle {
    background-color: #fdf2ee;
    border-radius: 4px;
    height: auto;
    border: 2px solid #deb4a9;
    margin-bottom: 10px;
    width: 100%;
    margin-top: 10px;
}

.bannerMessageStyle {
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.bannerMessage {
    font-size: 15px;
}

.textBold {
    font-weight: bold !important;
    font-size: 16px !important;
    cursor: pointer;
}

.linkFont {
    color: #6e6ca8 !important;
}

.passwordExpiryBanner {
    color: blue;
    cursor: pointer;
}
