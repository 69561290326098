.teamContainer {
    width: 100%;
    margin-top: 30px;
    padding-left: 0px !important;
}

.gridContainer {
    padding: 20px 0 !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.itemContainer {
    display: flex;
    justify-content: center;
}

.buttonNewMember {
    max-width: 150px;
    padding-left: 5px !important;
}

.buttonNewMemberDisabled {
    max-width: 150px;
    padding-left: 5px !important;
    color: rgba(0, 0, 0, 0.26) !important;
    border: 1px solid rgba(187, 119, 119, 0.12) !important;
}

.headerDiv {
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.pendingStatus {
    background-color: #ffdc9d;
    color: '#e67e00';
    padding: 5px;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 5px;
}
.activeStatus {
    background-color: #caf5c9;
    color: '#e67e00';
    padding: 5px;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 5px;
}
.pageTitle {
    font-weight: bold;
    font-size: 30px;
    margin: 0px;
}

.teamCard {
    border-radius: 4px !important;
    border: 1px solid #e8e9ec;
    box-shadow: none !important;
}

@media screen and (max-width: 599px) {
    .buttonNewMember {
        margin: 10px 0px !important;
        max-width: none !important;
        width: 100% !important;
    }
    .itemContainer {
        margin: 10px 5px !important;
    }
}

.Badge {
    padding: 0px 6px;
    border-radius: 4px;
    background-color: #d6ecff;
    font-weight: 200;
    margin-left: 15px;
}
