.transactionStatusHolder {
    width: fit-content;
    padding: 5px;
    border-radius: 4px;
    font-weight: 500;
}
.textStyle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
