.wrapper {
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff !important;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.paper {
    max-width: 500px !important;
    min-width: 250px !important;
}

@media screen and (max-width: 959px) {
    .wrapper {
        margin-top: 10px;
    }
}

.wrapperSingle {
    margin-bottom: 0px !important;
}

.nameContainer {
    display: inline-block;
    height: 100%;
    width: calc(100% - 500px);
}

.nameLogo {
    height: 100%;
    display: flex;
    align-items: center;
}

.logo {
    padding: 7px 9px;
    border-radius: 50%;
    background-color: #8783b5;
    color: #fff;
    font-size: 12px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 4px solid #f6f7fa;
}

.userName {
    font-size: 27px !important;
    font-weight: bold !important;
    margin-left: 10px !important;
    margin-right: 30px !important;
    max-height: 40px;
    overflow: hidden;
}

.viewBalanceContainer {
    display: inline-block;
    height: 46px;
}

.balance {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: #f6f7fa;
    border: 1px solid #e4e5e7;
    align-items: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
    font-weight: bold;
    justify-content: flex-end;
    padding-right: 10px;
    font-size: 17px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.balanceCurrencySign {
    color: #a0aac1;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.margin {
    margin-right: -8px;
}

.inputAndButtonContainer {
    display: inline-flex;
}

.inputContainer {
    display: inline-block;
    width: 150px;
}

.newInputContainer {
    display: inline-block;
    width: 150px;
}

.inputWrapper {
    height: 46px;
}

.newInputWrapper {
    height: 40px;
}
.inputBalance {
    height: 100% !important;
}

.inputBalance > input {
    font-size: 17px !important;
}

.dialogTitle {
    font-size: 20px;
    font-weight: bold;
}

.buttonActionContainer {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.newContainer {
    display: 'flex';
}

.newButtonActionContainer {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.buttonAction {
    height: 46px;
    width: 100%;
    color: #fff !important;
    background-color: #43425d !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer !important;
    &:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
        cursor: default !important;
    }
}

.buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nameButton {
    line-height: 20px;
    font-size: 17px;
    margin: 0;
    margin-left: 7px;
    text-transform: none;
}

.newContainer {
    display: flex;
    justify-content: flex-end;
}
.withdrawalMessage {
    margin: 10px 0px;
}

@media screen and (max-width: 1259px) {
    .nameContainer {
        width: calc(100% - 420px);
    }
    .buttonActionContainer {
        width: 100;
    }
}

@media screen and (max-width: 749px) {
    .newContainer {
        justify-content: flex-end;
    }
    .nameContainer {
        display: none;
    }
    .balance {
        width: 100%;
    }
    .inputContainer {
        width: 170px;
    }
    .buttonActionContainer {
        width: 100px;
    }
    .nameButton {
        display: block;
    }
    .wrapper {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 599px) {
    .newContainer {
        justify-content: flex-end;
    }
    .wrapper {
        height: 120px;
        display: block;
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
    }
    .nameContainer {
        display: none;
    }
    .viewBalanceContainer {
        display: block;
        margin-top: 8px;
    }
    .balance {
        width: 100%;
    }
    .inputAndButtonContainer {
        width: 100%;
    }
    .inputContainer {
        display: inline-block;
        min-width: 60%;
        margin-top: 8px;
    }
    .inputBalance {
        width: 100%;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
    .buttonActionContainer {
        display: inline-block;
        margin-top: 8px;
    }
}

@media screen and (max-width: 349px) {
    .newContainer {
        justify-content: end;
    }
    .inputContainer {
        min-width: 55%;
    }
    .wrapper {
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 301px) {
    .newContainer {
        justify-content: end;
    }
    .inputContainer {
        min-width: 65%;
    }
    .wrapper {
        display: flex;
        justify-content: flex-end;
    }
}

.balanceLoading {
    display: flex;
    width: 70px;
    height: 25%;
    justify-content: center;
}
