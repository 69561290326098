.dialogContentDatePicker {
    width: 165px !important;
    margin-top: -0.5% !important;
    margin-right: 3% !important;
}

.blackClass {
    border-color: black !important;
}

.defaultClass {
    border-color: grey !important;
}

.errorClass {
    border-color: red;
}

@media (max-width: 600px) {
    .MuiOutlinedInput-input {
        font-size: 14px;
        padding: 6px;
    }
}

@media screen and (max-width: 519px) {
    .paraText {
        width: 2.5rem !important;
    }
}
