.containerDiv,
.merchantChargesContainer {
    display: flex;
    padding-top: 16px;
    justify-content: space-between;
}
.exportSelect {
    height: 40px !important;
    background-color: transparent !important;
}
.filterBtn {
    border-radius: 10px !important;
    font-size: 10px !important;
    border-color: #005896 !important;
    color: #005896 !important;
}
.menuItem {
    height: 40px !important;
}
.flexDiv {
    display: flex;
}
.select {
    width: 70px !important;
    border: none !important;
}
.selectOne {
    width: 30px !important;
    border: none !important;
    margin-left: 10px !important;
}
.filterIcon {
    cursor: pointer;
}
.pageTitle {
    margin-left: 16px !important;
    font-size: 20px;
    font-weight: bold;
}
.rangeSelect {
    font-size: 16px !important;
    padding-right: 20px;
    z-index: 0;
}
.rangeSelectOne {
    font-size: 16px !important;
    padding-right: 20px;
    z-index: 0;
    margin-top: 7px !important;
}
.rangeSelectTwo {
    font-size: 16px !important;
    padding-right: 3px;
    z-index: 0;
    margin-top: 7px !important;
}
.addSpecialRentBtn {
    text-transform: none !important;
    border: none !important;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
.internalTransferPending {
    :nth-child(1) {
        color: #a0aac1;
        margin-right: 5px;
    }
    :nth-child(2) {
        color: #a0aac1;
        font-size: 16px;
        font-weight: bold;
    }
    display: flex;
    margin-right: 20px;
}
.datepicker {
    margin-left: 105px;
}
.daterangepicker {
    margin-left: 1px;
}

.filterButton {
    padding-right: 10px;
    width: 250px !important;
}

.selectedButton {
    border: 2px solid #42a5f5 !important;
}

#__range-picker-container .calendar {
    left: 50px !important;
    top: 30px !important;
}
@media screen and (max-width: 640px) {
    .merchantChargesContainer {
        flex-wrap: wrap;
    }

    .mobileMC {
        width: 100%;
    }
}
@media screen and (max-width: 599px) {
    .select {
        top: 0 !important;
    }
    .filterButton {
        padding-right: 5px;
        width: 230px !important;
    }
    .pageTitle {
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
    }
    .rangeSelect {
        font-size: 10px !important;
        padding-right: 10px;
        input {
            padding: 4px 0;
        }
    }
    .exportButton {
        margin: 0 !important;
        z-index: 0;
        button {
            font-size: 11px;
            padding: 2px 5px;
            min-width: 50px;
        }
    }
    .exportSelect {
        height: 30px !important;
        width: 110px !important;
        background-color: transparent !important;
        > div {
            font-size: 12px !important;
        }
    }
    .rangeSelectOne {
        font-size: 16px !important;
        z-index: 0;
        margin-top: 0px !important;
    }
    .flexDiv {
        display: flex;
        row-gap: 10px !important;
    }
}

@media screen and (max-width: 399px) {
    .payoutFlexDiv {
        width: 40%;
    }
    .exportButton {
        margin: 0 !important;
        z-index: 0;
        button {
            font-size: 11px;
            padding: 2px 5px;
            min-width: 50px;
        }
    }
    .payoutFlexDiv {
        .exportButton {
            top: 15px !important;
        }
    }
    .merchantChargesContainer {
        .exportButton {
            right: 20px !important;
            top: 55px !important;
        }
    }
}
