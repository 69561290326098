.headerTable {
    margin-top: 40px;
    margin-left: 10px;
    font-size: 20px;
}

.container {
    margin-bottom: 40px;
}

.datePicker {
    margin-left: 30%;
    display: inline-flex;
}

@media screen and (max-width: 599px) {
    .datePicker {
        display: block;
        margin: 20px 0;
    }
}
