.wrapper {
    display: flex;
    &__inputs {
        width: 50px;
        height: 30px;
        background-color: white;
        input {
            text-align: center;
            letter-spacing: 0.1em;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
                'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 16px;
            border: '2px solid #ff2e58';
        }
        &__dash {
            font-weight: 600;
            margin: 0 13px;
        }
        &__disableInput {
            background-color: gainsboro !important;
        }
        &__emptyInput {
            fieldset {
                border: 2px solid #ff2e58 !important;
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .wrapper {
        width: 70%;
        &__inputs {
            height: 27px;
        }
    }
}
