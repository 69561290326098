.closeIcon {
    float: right;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
        border-radius: 1px;
    }
}
.feeTierModalTitle {
    justify-content: space-evenly;
    .headingTitle {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        display: block;
        text-align: left;
    }
}
.actionBtn {
    width: 100%;
    max-width: 150px !important;
}
.btnContainer {
    display: flex;
    justify-content: space-evenly !important;
    width: 100%;
}
.inputFeeTierValue {
    width: 100%;
    cursor: pointer;
}
.inputFixedFeeValue {
    width: 100%;
    cursor: pointer;
}
.dialogContent {
    width: 450px !important;
}
.hintMessage {
    font-size: x-small;
    color: grey;
    width: 200px;
}
.headerDivider {
    height: 0.5px;
    width: 100%;

    background-color: lightgray;
}
.feeTierDialog {
    padding: 3px;
    width: 100%;
}
.percentageFeeStyle {
    margin-bottom: 15px;
}
.fixedFeeStyle {
    margin-bottom: 35px;
}
.dialogBtn {
    width: 100%;
    max-width: 150px !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.countryStyle {
    width: 50%;
}
.countryStyle div:first-child {
    margin: 0 0 15px 0 !important;
}
.selectCountry {
    border: 1px solid #999;
    border-radius: 3px;
    height: 30px;
    padding-left: 5px;
    width: 100%;
}
.counrtySelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
    font-size: 15px !important;
}

@media screen and (max-width: 640px) {
    .selectCountry {
        width: 100%;
        max-width: 100% !important;
    }
    .countryStyle {
        width: 100%;
        max-width: 100% !important;
        margin-left: 2px !important;
    }
    .dialogContent {
        max-width: 100% !important;
        overflow: hidden !important;
    }
    .actionBtn {
        width: 100%;
        max-width: 40% !important;
    }
    .dialogBtn {
        width: 100%;
        max-width: 40% !important;
    }
}
