@media screen and (min-width: 519px) {
    .boxStyle {
        width: 400px;
    }
}
.dialogContentTextField {
    width: 150px !important;
    margin-top: -0.5% !important;
    margin-left: 39px !important;
    margin-right: 3% !important;
}
.dialogContentTextFieldWeekly {
    width: 159px !important;
    margin-top: -0.5% !important;
    margin-left: 39px !important;
    margin-right: 3% !important;
}
.dialogContentTextFieldTo {
    width: 163px !important;
    margin-top: -0.5% !important;
}
.dialogContentDatePickerVariant {
    width: 163px !important;
    margin-top: -0.5% !important;
    margin-left: 59px !important;
    margin-right: 3% !important;
}
.dialogContentDatePicker {
    width: 163px !important;
    margin-top: -0.5% !important;
    margin-right: 3% !important;
}

.dialog {
    width: 100% !important;
    // max-width: 700px !important;
    // min-width: 450px !important;
}
.title {
    padding-bottom: 0px !important;
    width: 400px !important;
}
.dialogContent {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 23px !important;
}
.dialogContento {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 4rem !important;
    > div {
        display: flex !important;
        flex-direction: row !important;
        gap: 4.1rem !important;
    }
}
.dialogContentDownloadFormat {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 1rem !important;
}
.dialogContentp {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 4rem !important;
    > div {
        display: flex !important;
        flex-direction: row !important;
        gap: 1.5rem !important;
    }
}

.paraText,
.paraTextCustom {
    font-size: 18px !important;
}

.exportSelect {
    height: 40px !important;
    width: 157px !important;
    background-color: transparent !important;
}
.selectTimeFrame {
    margin-top: 10px !important;
    margin-left: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: baseline !important;
    gap: 25px !important;
}
.datePicker {
    width: 200px !important;
}
.dialogActions {
    margin-top: 20px !important;
}
.downloadFormat {
    margin-top: -1.5% !important;
    margin-left: 6.5% !important;
}
@media screen and (max-width: 519px) {
    .dialogContento {
        > div {
            display: flex !important;
        }
        width: 100% !important;
        margin-top: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
    }
    .paraTextCustom {
        width: 2.5rem !important;
    }
    .dialogContentDatePickerVariant {
        width: 166px !important;
        margin-top: -0.5% !important;
        margin-left: 59px !important;
        margin-right: 3% !important;
    }
    .dialogContentDatePicker,
    .dialogContentTextFieldTo {
        width: 157px !important;
        margin-top: -0.5% !important;
        margin-right: 3% !important;
    }
}

@media screen and (max-width: 376px) {
    .dialogContentp {
        width: 100% !important;
        margin-top: 10px !important;
        display: flex !important;
        flex-direction: row !important;
        gap: 4rem !important;
        overflow: hidden;
        > div {
            display: flex !important;
            flex-direction: row !important;
            gap: 1.5rem !important;
        }
    }
    .dialogContentDatePickerVariant {
        width: 160px !important;
        margin-top: -0.5% !important;
        margin-left: 58px !important;
        margin-right: 3% !important;
    }
}
