.radio_btn {
    cursor: pointer;
    padding: 0px;
}
.dialog {
    overflow: hidden !important;
}
.table {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    width: 1050px !important;
}
.formContainer {
    display: flex;
    flex-direction: column;

    .formContainerItem {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        gap: 3rem;
        align-items: baseline;
        justify-items: center;
    }
}
.dialogContent {
    overflow: hidden !important;
}
.refundProcess {
    width: 104%;
    margin-left: 4px !important;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.bannerPara {
    text-align: center;
    font-size: 18px;
}

.dropdown {
    width: 200px;
}

.specialRentDialog {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.dialogbtn {
    text-align: center !important;
}
.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
}

.rentValue {
    width: 98%;
    margin-left: 2%;
}
.title {
    padding-bottom: 0px !important;
}
.formContainer {
    padding: 5%;
    padding-top: 0px;
}
.rentfields {
    margin-top: 15px;
    margin-left: 2%;
}
.closeIcon {
    &:hover {
        background-color: #f6f7fa;
        cursor: pointer;
    }
}
.radio_btn {
    color: black;
}
.datePicker {
    border: 1px solid #e9e9ec;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 4px !important;
    border-radius: 4px !important;
    color: gray;
    min-width: 200px;
    text-align: left;
    font-size: 16px;
    width: 320px !important;
    background-color: #fff;
}
.amountAlert {
    margin-top: 10px !important;
    margin-left: 1px !important;
    width: 105% !important;
}

@media screen and (max-width: 600px) {
    .datePicker {
        border: 1px solid #e9e9ec;
        padding-top: 1px !important;
        padding-bottom: 1px !important;
        padding-left: 4px !important;
        border-radius: 4px !important;
        color: gray;
        min-width: 120px;
        text-align: left;
        font-size: 16px;
        width: 100% !important;
        background-color: #fff;
    }

    .rentValue {
        width: 97%;
        margin-left: 3%;
        //height :10px;
    }
    .refundProcess {
        width: 100%;
        margin-left: 4px !important;
        &:not([disabled]) {
            background-color: green !important;
            color: white !important;
        }
    }
}

.select {
    width: 27.5rem !important;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    text-align: left !important;
}

.TableContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 3%;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    width: 1000px !important;
}
.nameColumn {
    max-width: 140px !important;
    min-width: 140px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -5px !important;
    margin-top: -4px !important;
}
.nameColumnCell {
    max-width: 300px !important;
    min-width: 100px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px !important;
}
.merchantName {
    transition: background-color 0.5s;
    background-color: transparent;
    user-select: none;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    cursor: pointer;
}
.numberInput {
    height: 40px;
    background-color: white;
    width: 104.5%;
    padding: 0;
    margin-left: 1%;
    border-radius: 4;
    position: 'relative';
    border: '1px solid #e8e9ec';
    font-size: 16;
}
@media screen and (max-width: 500px) {
    .TableContainer {
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 5px;
        justify-content: center;
        text-align: center;
        width: 700px !important;
    }
    .nameColumn {
        max-width: 100px !important;
        min-width: 100px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 3px !important;
        margin-top: -5px !important;
    }
    .numberInput {
        height: 40px;
        background-color: white;
        width: 100%;
        padding: 0;
        margin-left: 1%;
        border-radius: 4;
        position: 'relative';
        border: '1px solid #e8e9ec';
        font-size: 16;
    }
}
.modalContainer {
    overflow: hidden !important;
}
