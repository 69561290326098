.content {
    max-width: 400px !important;
    margin-bottom: 24px !important;
}

.updatePasswordCard {
    margin-top: 30px;
    border-radius: 4px !important;
    border: 1px solid #e8e9ec;
    box-shadow: none !important;
}

@media screen and (max-width: 599px) {
    .content {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

.pageTitle {
    padding-left: 20px;
    padding-top: 20px !important;
    font-weight: bold !important;
    font-size: 30px !important;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-bottom: 20px !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.inputContainer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.buttonContainer {
    padding-top: 10px;
}

.buttonUpdatePass {
    width: 100%;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.validationNote {
    padding-top: 35px;
}

@media screen and (max-width: 599px) {
    .validationNote {
        padding-top: 20px;
        margin-bottom: 40px;
        justify-content: center;
    }
}

.doneIcon {
    color: green;
}

.crossIcon {
    color: red;
}

.validationText {
    padding-top: 3px;
}

.validationTextContainer {
    padding-left: 10px !important;
}

.alertError {
    width: 100%;
}
