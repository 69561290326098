.container {
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    overflow-y: hidden;
}
.actionButton {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between !important;
    margin: 20px 0 10px;
}
.actionButton button {
    width: 48%;
}
.amountContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
}
.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.amountInput {
    padding-right: 35px;
    margin-bottom: 15px !important;
    height: 36px;
    width: 195px;
    background-color: white !important;
    &_input {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
}

.createQRTitle {
    font-weight: bold;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}
.copyIconContainer {
    display: flex;
    max-width: 40px !important;
    padding-left: 15px;
}

.customInput:disabled {
    background-color: #666 !important;
}
.input {
    width: 100%;
    max-width: 400px;
    input {
        height: 35px;
        padding: 0 10px !important;
    }
}
.qrModelInput {
    input {
        height: 35px;
        padding: 0 10px !important;
    }
}
.linkInput {
    width: 100%;
    max-width: 400px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dateInput {
    margin-top: -38px !important;
    margin-bottom: 35px !important;
}

.qrModelInput {
    border-radius: 4px !important;
    position: relative;
    background-color: white !important;
    font-size: 16px !important;
    width: 100%;
    max-width: 400px;
}

.title_info {
    font-weight: 600;
    color: #666;
    margin-top: 25px;
}
.loading {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 100px;
}
.labelText {
    display: flex;
    margin-top: 10px;
}
.description div:first-child {
    margin-top: 10px !important;
}

.labelTextType {
    font-weight: 500 !important;
}
.radioContainer {
    display: flex;
}
.datePicker {
    max-width: fit-content !important;
}
.radioBtn {
    display: flex;
    margin: 0 5px;
}
.checkBox {
    margin-top: 30px;
}

.linkField {
    margin-top: 0px !important;
    margin-bottom: -30px !important;
}
.validUrl {
    margin-left: 0 !important;
}
.qrModalValidInput {
    position: absolute;
    left: 55px;
    color: red;
}
.qrModal {
    width: 420px;
}
.dateText {
    height: 36px !important;
    background-color: white !important;
    &__input {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
}
.expiryDateInput {
    height: 36px !important;
    background-color: white !important;
    &__input {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
}
@media screen and (max-width: 560px) {
    .qrModal {
        width: 100%;
    }
}
@media screen and (max-width: 280px) {
    .amountInput {
        padding-right: 35px;
        margin-bottom: 15px !important;
        height: 36px;
        width: 168px;
        background-color: white !important;
        &_input {
            font-size: 16px !important;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
                'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
        }
    }
}
