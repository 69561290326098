.divTableFooter {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.currencySpan {
    padding-left: 5px;
    color: gray;
}
.amountSpan {
    font-weight: bold;
}
.columnSpan {
    font-weight: 500;
}
