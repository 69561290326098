.registerContainer {
    margin-top: 30px !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}

.registrationTitle {
    font-size: 20px;
    font-weight: 700;
}

.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}

.resellerInfoContainer {
    border-bottom: 1px solid #e3e8ee;
    padding-bottom: 20px;
}

.formContainer {
    margin-top: 20px;
}

.formTitle {
    font-weight: 700 !important;
    font-size: 16px !important;
}

.subTitle {
    margin-left: 4.3%;
    margin-top: 20px !important;
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

.inputLookupCompany {
    background-color: white;
}

.customInput {
    width: 100%;
}

.withoutBorders {
    border: 1px solid #fcfdfe;
    border-radius: 5px;
    padding-right: 5px;
}

.companyContainer {
    align-items: center;
}

.textButton {
    line-height: 18px;
}
.lookupCompanyButton {
    width: 100%;
    height: 40px;
}

.amountInput {
    height: 34px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}
.companyNumber {
    height: 34px;
    width: 100%;
    border-radius: 4px;
    border: 1px !important;
}

.activateReseller {
    margin-top: 30px;
    margin-bottom: 30px;
}

.buttonStyle {
    width: 350px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.selectTermsAndConditionInput {
    margin-top: 10px;
    border: 1px solid #e8e9ec !important;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px !important;
}

.selectTermsAndConditionInput:hover {
    outline: 0;
    box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
    border-color: #43425d !important;
}

.tableContainer {
    margin-top: 15px !important;
    margin-bottom: 30px !important;
}

.pdfIcon {
    margin-right: 10px;
    color: #43425d !important;
}

.allowedFormateStyle {
    margin-top: 10px !important;
    font-size: 15px !important;
}

.termsAndConditionsContainer {
    margin-left: 4.3% !important;
    margin-top: 20px !important;
}

.disableInputClass {
    pointer-events: none;
    opacity: 0.4;
}

@media screen and (max-width: 599px) {
    .subTitle {
        margin-left: 8.3%;
    }
    .termsAndConditionsContainer {
        margin-left: 8.3% !important;
    }
    .lookupCompanyButton {
        margin-top: 10px !important;
    }
}
