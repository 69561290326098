.formContainer {
    margin-top: 5px !important;
}
.datePickerContainer {
    margin-top: 10px;
    margin-bottom: 25px;
    display: flex;
}
.startDatePick {
    width: 50%;
}
.endDateDatePick {
    margin-left: 20px;
    width: 50%;
}
.datePicker {
    display: inline;
    font-size: 14px !important;
    color: #43425d !important;
    width: 100%;
    max-width: max-content;
    padding-top: 1px !important;
    input {
        padding: 10px 14px;
    }
}
.checkBoxContainer {
    margin-top: 25px;
    margin-left: 25px;
    width: 100%;
}
.midCheck {
    align-items: flex-end;
    display: flex;
    margin-top: 5px;
    label {
        margin: 0;
    }
}
.datePickerWidth {
    width: 100%;
}
@media screen and (min-width: 280px) and (max-width: 640px) {
    .datePickerContainer {
        flex-wrap: wrap;
        padding: 0 10px;
    }
    .startDatePick {
        width: 100%;
    }
    .endDateDatePick {
        margin-top: 10px;
        width: 100%;
        margin-left: 0 !important;
    }
    .checkBoxContainer {
        margin-top: 15px;
        margin-left: 0 !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .datePicker {
        input {
            padding: 10px 14px;
            width: 160px;
        }
    }
    .checkBoxContainer {
        width: 100%;
    }
}
@media screen and (max-width: 599px) {
    .datePickerWidth {
        max-width: 150px !important;
    }
}
.inputMessage {
    width: 100%;
    background-color: white;
}

.button {
    width: 100%;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.charCount {
    text-align: right;
    color: rgba(0, 0, 0, 0.54);
}
.tag_input {
    display: flex;
    flex-wrap: wrap;
    padding: 2px 8px;
    border: 1px solid #d6d8da;
    border-radius: 4px;
    width: 100%;
}
.tag_input TextField {
    flex: 1;
    border: none;
    font-size: 14px;
    padding: 4px 0 0;
}
.tag_input TextField:focus {
    outline: transparent;
}
.tag_input div {
    display: flex;
    flex-wrap: wrap;
}
.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
    flex-wrap: wrap;
}

.tag_title {
    margin-top: 0;
}

.tag_close_icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}
