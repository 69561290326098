.checkboxWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
}

.buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    justify-content: center !important;
}

@media screen and (min-width: 519px) {
    .boxStyle {
        width: 400px;
    }
}
@media screen and (max-width: 599px) {
    .exportrModalBox h2 {
        font-size: 14px;
    }
    .exportrModalBox .payments-none {
        font-size: 13px;
    }
}
.divStyle {
    display: flex;
}
