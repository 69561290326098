.alertError {
    margin-top: 30px;
}

.pendingMerchantsButton {
    margin-top: 20px !important;
}

.thirdId {
    margin-top: 25px !important;
}

.addUrgentMessageButton {
    margin-top: 20px !important;
}
.feeTierButton {
    margin-top: 15px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 180px;
}
.creditBtn {
    margin-top: 30px;
}
.borderAddUrgentMessage {
    border-top: 1px gray solid;
    border-bottom: 1px gray solid;
    padding-bottom: 20px;
}
.mainContainer {
    margin-top: 30px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.title {
    font-size: 21px;
    font-weight: bold;
}
.subTitle {
    font-size: 16px;
    font-weight: bold;
}
.divSubTitle {
    padding: 16px 16px 0px;
}
.smallContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    margin-left: 20px;
}

.feesContainer {
    display: block;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 25px !important;
}
.dropDown {
    margin-top: 20px;
    flex-grow: 1;
}
.adyenGrid {
    padding: 10;
}
.dateDiv {
    display: flex;
}

@media screen and (max-width: 599px) {
    .smallContainer {
        flex-direction: column-reverse;
    }
}
