.container {
    &__img {
        max-height: 20em;
        margin-top: 6em;
        margin-bottom: 4em;
        cursor: pointer;
        width: 170px;
    }
    &__redirect {
        margin-right: 10px;
        margin-top: 15px;
        &__text {
            font-size: 18px !important;
        }
    }
    &__text {
        margin-top: 7vh !important;
        text-align: center;
        user-select: none;
        font-size: 18px !important;
    }
    &__spinner {
        color: #3a8ac6 !important;
    }
    &__anchor {
        cursor: pointer;
        margin-left: 10px;
        text-decoration: underline;
    }
}
