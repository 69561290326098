.createUserForm {
    text-align: center;
}

.alertWindow {
    margin: 20px 20px 0px 20px;
}

.loadingSubmit {
    margin-top: 10px;
}
