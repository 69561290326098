.updateAttributeContainer {
    margin-top: 20px;
    :nth-child(n + 3) {
        margin-top: 5px !important;
    }
}

.actionButtonHolder {
    margin-left: 10px !important;
}
.actionButtonHolderOk {
    margin-left: 10px !important;
}
.actionButtonHolderUpdate {
    margin-top: -1px !important;
}
.userName {
    margin-top: 10px !important;
}

.phoneInput {
    width: 100% !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

@media only screen and (max-width: 600px) {
    .attributes {
        display: flex;
    }
    .newContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-left: 1em !important;
    }
    .updateAttributeContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-top: 20px;
        :nth-child(n + 3) {
            margin-top: 5px !important;
        }
    }
    .actionButtonHolder {
        margin-left: 0em !important;
    }
    .actionButtonHolderUpdate {
        margin-top: 0px !important;
        margin-left: 0em !important;
    }
    .userName {
        margin-left: 0.5em !important;
    }
}

@media only screen and (max-width: 1150px) and (min-width: 601px) {
    .attributes {
        display: flex;
    }
    .newContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-left: 1em !important;
    }
    .updateAttributeContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-top: 20px;
        :nth-child(n + 3) {
            margin-top: 5px !important;
        }
    }
    .actionButtonHolder {
        margin-left: 0em !important;
    }
    .actionButtonHolderOk {
        margin-left: 0em !important;
        margin-top: 2px !important;
    }
    .actionButtonHolderUpdate {
        margin-top: -1px !important;
        margin-left: 0em !important;
        padding-left: 0em !important;
    }
    .userName {
        margin-left: 0.5em !important;
    }
}
@media only screen and (max-width: 640px) {
    .actionButtonHolderOk {
        margin-left: 0px !important;
    }
}
