.paymentsNone {
    padding: 32px 16px;
    text-align: center;
}
.resultSpan {
    margin-left: 10px;
    padding-bottom: 10px;
}
.buttonDiv {
    margin-top: 10px;
    max-width: 120px;
}
.referenceColumn {
    min-width: 270px;
}
.resendBtns {
    max-width: 160px;
    margin: 5px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.buttons {
    display: grid;
}

@media screen and (max-width: 599px) {
    .tableRow > * {
        padding: 5px !important;
    }
    .referenceColumn {
        min-width: 240px;
    }
}

.in_batch_container {
    display: flex;
}

.expand__arrow {
    margin-right: 5px;
    cursor: pointer;
    &__disabled {
        cursor: auto;
        opacity: 0.2;
    }
}
