.title {
    margin-bottom: 10px;
}

.documentReviewContainer {
    margin-top: 20px;
    padding: 16px;
}

.documentTable {
    margin-top: 25px;
}

.documentFileName {
    min-width: 200px;
    max-width: 200px;
}

.documentUploadDate {
    min-width: 100px;
    max-width: 100px;
}

.inputlabel {
    margin-left: 10px;
}

.documentStatusCell {
    display: flex !important;
}

.documentSelectReason {
    width: 10px;
    min-width: 215px;
    margin-left: 10px;
}

.selectDocType {
    width: 10px;
    min-width: 215px;
}

.selectDocumentReviewReason {
    font-weight: 500 !important;
    border-radius: 4px !important;
    min-width: 190px !important;
    height: 40px;
    padding: 11.5px 14px !important;
    font-size: 0.875rem !important;
    width: 100% !important;
    position: relative;
    background-color: #fff !important;
    text-indent: 10px;
}

.fileName {
    color: #2c7be5;
    &:hover {
        color: purple !important;
    }
    cursor: 'pointer';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
}

.adyenUpload {
    background-color: green !important;
    color: white !important;
    font-size: 12px !important;
}
.documentPagination {
    width: 675px;
    border: 1px;
}

.documentReviewStatus {
    margin-left: 22px;
}

@media screen and (min-width: 749px) {
    .selectDocumentReviewReason {
        min-width: 150px !important;
    }
}
