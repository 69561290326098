.stepThreeContainer {
    min-height: 400px;
}

.yes_no_Switch {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
}

.checkBoxText {
    font-size: 14px !important;
}

.customInput {
    width: 100%;
    height: 36px;
    background-color: white;
}

.boldText {
    font-size: 14px !important;
    font-weight: bold;
}

.autoRenewalLabel {
    margin-top: 30px !important;
}

@media screen and (min-width: 600px) {
    .autoRenewalSwitch {
        margin-top: 30px !important;
    }
}

.motoReasonContainer {
    height: 100px;
}
