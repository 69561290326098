.stepsContainer {
    margin-top: 20px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.hidePaper {
    display: none;
}

.accordionItem {
    box-shadow: none !important;

    border-radius: 0px !important;
    width: 100%;
}

.list {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.listItem {
    border-bottom: 1px solid #e8e9ec;
    min-height: 61px;
}

.listItemLast {
    border-bottom: none !important;
    min-height: 61px;
}

.btnText {
    text-transform: none;
}

.mainTitle {
    font-size: 21px;
    font-weight: bold;
}

.accordionDetails {
    display: flex;
    flex-direction: column;
}

.accordionDetailsBtn {
    width: fit-content;
    margin-left: 40px !important;
    font-weight: 100 !important;
}

.textBold {
    font-weight: bold !important;
}

.listItemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}

.summaryContainer {
    display: flex;
}
.itemContainerText {
    margin-left: 20px;
}

.linkFont {
    color: #6e6ca8 !important;
}

.expandedBackGroundColor {
    background-color: #f5f9fb !important;
}

.error_icon {
    color: #f44336 !important;
}

.success_icon {
    color: #4caf50 !important;
}

.center {
    padding-left: 20px;
}

.itemContainerText2 {
    margin-left: -16px;
}

.last__listItem {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
}

.merchant__creation__container {
    justify-content: space-between !important;
    align-items: center !important;
}

.merchant__creation__message__remove_icon {
    color: #000000 !important;
    cursor: pointer !important;
}

.merchant_remove_icon {
    color: #000000 !important;
    cursor: pointer !important;
    margin-left: 50px;
}
.text__option__container {
    width: 93% !important;
    font-size: 15px;
}

.textBold {
    font-weight: bold !important;
    font-size: 16px !important;
    cursor: pointer;
}

.linkFont {
    color: #6e6ca8 !important;
}

.bannerStyle {
    background-color: #fdf2ee;
    border-radius: 4px;
    height: auto;
    border: 2px solid #deb4a9;
    margin-bottom: 10px;
    width: 100%;
    margin-top: 10px;
}

.bannerMessageStyle {
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.bannerMessage {
    font-size: 15px;
}

.iconUnverified {
    margin: 0 16px 0 16px;
}

.error_icon {
    font-size: 20px !important;
    color: #f44336 !important;
}

.passwordExpiryBanner {
    color: blue;
    cursor: pointer;
}
@media screen and (max-width: 959px) {
    .itemContainerText2 {
        margin-left: 5px;
    }
    .merchant_remove_icon {
        margin-left: 0px;
    }
}

@media screen and (max-width: 399px) {
    .itemContainerText2 {
        display: flex;
        flex-wrap: wrap;
        margin-left: 25px;
    }
    .merchant_remove_icon {
        margin-left: 0px;
    }
}
.continueOnboardingContainer {
    background-color: white;
    padding-left: 2% !important;
    margin-top: 20px;
    height: auto;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}
.continueOnboarding {
    background-color: #d3eff5 !important;
}
