.headerDivider {
    height: 0.5px;
    width: 600px;
    margin-left: -24px;
    margin-top: 15px;
    margin-right: 150px;
    background-color: lightgray;
}
.title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}
.spanMessage {
    color: #8783b5;
}
.spanErrorMessage {
    color: red;
}
.supplier {
    color: #43425d;
}
.withdrawalArrivingText {
    padding: 20px 0;
    max-width: 100%;
}
.customOutlineInput {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #e8e9ec;
    font-size: 15px;
    height: 35px;
    width: 100%;
}
.withdrawalArrivingText {
    padding-bottom: 20px;
    max-width: 100%;
}
.buttonsContainer {
    margin: 30px 0;
}

.button {
    width: 80%;
}
.transferLastText {
    margin-top: 50px;
}
