.container {
    margin-bottom: 100px;
    margin-top: 30px;
}

.loading {
    text-align: center;
}

.idsColumn {
    min-width: 210px;
    max-width: 210px;
}

.searchPaper {
    background-color: inherit !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    margin-top: 40px;
}

.searchMerchants {
    background-color: white;
    min-width: 300px !important;
    margin-right: 20px !important;
}

.searchButton {
    margin-top: 2px !important;
}
.search_form {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.pending__check_box {
    margin-left: 20px !important;
}

.nameColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 195px;
    max-width: 195px;
}

.postcodeColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;
    max-width: 100px;
}
.inputlabel {
    margin-bottom: 100px;
}
.formControl {
    margin-left: 20px !important;
}
.selectInput {
    background-color: white;
    min-width: 250px !important;
    margin-right: 10px !important;
    height: 40px;
}

.paymentsNone {
    margin-left: 440px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.resellerIdColumn {
    width: 110px;
}
.copyIconContainer {
    display: flex;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}

.textInput {
    border: none !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
}

.idContainer {
    width: calc(210px + 10px) !important;
}

.resellerNameStyle {
    cursor: pointer;
    min-width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (max-width: 749px) {
    .search_form {
        display: flex;
        flex-direction: column;
    }
    .pending__check_box {
        margin-left: 0px !important;
    }
}

.inputlabel {
    margin-bottom: 100px;
}

.formControl {
    margin-left: 20px !important;
}
.selectInput {
    background-color: white;
    min-width: 250px !important;
    margin-right: 10px !important;
    height: 40px;
}
.paymentsNone {
    margin-left: 440px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.tableContainer {
    border: 1px solid #e8e9ec;
    border-radius: 4px !important;
    box-shadow: none;
}

.merchantName {
    &:hover {
        color: #212529 !important;
    }
}
