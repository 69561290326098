.label {
    position: relative !important;
}

.labelText {
    font-size: 15px !important;
}

.bold {
    font-weight: bold !important;
}
