.card {
    border-radius: 4px !important;
    height: 170px;
    border: 1px solid #e8e9ec;
    box-shadow: none !important;
}

.card_name {
    font-size: 20px;
    margin-left: 10px;
}

.country {
    float: right;
}

.balance {
    margin-top: auto !important;
    height: calc(100% - 68px);
    align-items: baseline !important;
}

.action_items {
    height: 53px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;

    &__account_balance {
        display: flex;
        align-items: center;
        min-width: 140px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bold;
        margin-top: 10px;
        font-size: 24px !important;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
    &__currency {
        font-size: 24px !important;
        color: #a0aac1;
        float: left;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
}

@media screen and (max-width: 749px) {
    .card {
        height: 130px;
        margin-top: 15px;
    }
    .action_items__account_balance {
        margin-top: 0;
    }
    .title {
        padding-bottom: 0 !important;
    }
}
