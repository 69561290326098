.switchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.yes_no_Switch {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
}

@media screen and (max-width: 599px) {
    .yes_no_Switch {
        margin-top: 10px !important;
    }
}

.checkBoxText {
    font-size: 14px !important;
}

.whiteRoundBorders {
    border: 1px solid #fcfdfe;
    border-radius: 4px;
    padding: 10px;
    //margin: 10px !important;
}

.inBorderText {
    span {
        font-size: 13px !important;
        font-weight: bold;
    }
}

.nonRoundBorders {
    border-radius: 4px;
    padding: 10px;
    //margin: 10px !important;
}

.arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        cursor: pointer;
        margin-top: 5px;
    }
}

.inputContainer {
    background-color: #fff;
    border: 1px solid #dfe3e6;
    border-radius: 4px;
}

#combo-box-demo {
    .MuiAutocomplete-inputRoot {
        padding: 0px !important;
    }
}

.borderItems {
    display: flex;
    justify-content: space-between;
}

.withSpasing {
    margin: 5px !important;
}

.disabledText {
    color: #bdbdbd;
}
