.paper {
    width: 100%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.dilogbtn {
    width: 57%;
}

.leavebtn {
    width: 41%;
}

.btnContainer {
    margin-right: 10px;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.verificationTitle {
    padding-bottom: 0;
    margin: 0;
}

.verificationSubtitle {
    font-size: 0.9em;
    color: darkgreen;
}

.otpField {
    width: 100%;

    &.active {
        letter-spacing: 0.5em;
    }
}

.buttonContainer {
    & .resendBox {
        display: inline-block;
        width: 40%;
        margin-right: 4%;
    }

    & .submitBox {
        display: inline-block;
        width: 55%;
    }
}

.resendButton {
    width: 100%;
}

.submitButton {
    width: 100%;
    background: darkgreen !important;
    color: white !important;
}

.resendButtonText {
    font-size: 0.7em;
    font-weight: 300;
    color: rgb(30, 90, 201);
    margin-bottom: 0;
    min-height: 20px;
}

.invalidFeedback {
    display: block;
    margin: 0;
    font-weight: 500;
    font-size: small;
    min-height: 20px;
}

//footer
.footer {
    padding: 0.3em;
}

.footerText {
    font-size: 0.75em;
    text-align: center;
    font-size: 0.7em;
}

.marginTop {
    margin-top: 1rem !important;
}

.marginBottom {
    margin-bottom: 1rem !important;
}

.bold {
    font-weight: bold;
}

.actionFormContainer {
    width: 60%;
    margin: auto !important;

    @media (min-width: 768px) {
        max-width: 80%;
    }

    @media (max-width: 667px) {
        width: 100%;
    }
}
