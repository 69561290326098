.uploadDocumentContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.container {
    border: 1px solid #e8e9ec;
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}
.upload {
    margin: auto !important;
    width: 90% !important;
    max-width: 900px;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 4px !important;
}

@media screen and (max-width: 599px) {
    .container {
        width: 100% !important;
    }
}

.documents {
    margin-top: 30px;
}

.subHeaderBackground {
    background-color: #f5f9fb !important;
    padding: 20px !important;
}

.paddingHeader {
    padding-left: 16px !important;
    padding-top: 16px !important;
    padding-bottom: 16px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    font-size: 21px;
    font-weight: bold;
}

.popupText {
    display: flex;
    align-items: baseline;
}
.listItemContainer {
    background-color: white;
    margin-top: 20px;
    margin-left: 65px;
    height: auto;
    min-height: 50px;
    border-radius: 4px;
    width: 86%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    svg {
        color: #a0aac1;
        font-size: 18px;
    }
}

.iconUnverified {
    margin: 0 20px 0 30px;
}
.alert_background__color {
    background-color: #fdf2ee;
    border: 2px solid #deb4a9;
}
.error_icon {
    font-size: 23px !important;
    color: #f44336 !important;
}
.updateBtn {
    width: 40%;
    max-width: 450px;
    margin-left: 30px;
}
