.containerDialog {
    max-width: 650px !important;
}

.headerDivider {
    height: 0.5px;
    width: 100%;
    margin-top: 15px;
    background-color: lightgray;
}

.headerText {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
}
.button {
    width: 90%;
    margin: 0 5% 20px 5% !important;
}
