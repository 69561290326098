.homeLogo {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 170px;
    }
}

@media screen and (max-width: 600px) {
    .homeLogo {
        height: 170px;
        img {
            width: 130px;
        }
    }
}
