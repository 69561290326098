.wrapper {
    display: flex;
    justify-content: space-between;
    &__inputs {
        height: 33px !important;
        background-color: white;
        input {
            text-align: center !important;
            letter-spacing: 0.1em;
            padding: 6px 0 6px !important;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
                'Apple Color Emoji', 'Segoe UI Emo';
            font-size: 16px !important;
        }
        &__dash {
            font-weight: 600;
            margin: 5px 13px;
        }
        &__disableInput {
            background-color: gainsboro !important;
        }
        &__first {
            width: 100px !important;
        }
        &__second {
            width: 120px !important;
        }
        &__third {
            width: 90px !important;

        }
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        &__inputs {
            height: 27px;
            input {
                padding: 0 !important;
                text-align: center;
            }
            &__first {
                width: 80px !important;
            }
            &__second {
                width: 90px !important;
            }
            &__third {
                width: 55px !important;

            }
        }
    }
}
