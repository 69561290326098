.paper {
    max-height: '70vh';
    margin-top: 5%;
}
.header {
    position: 'sticky';
    top: '0';
}
.tableHeader {
    width: '20px';
}
.searchGrid {
    margin: 5% 5% 5% 2%;
}

.searchMerchants {
    margin-left: 10%;
    background-color: white;
    width: 300px;
    margin-right: 20px !important;
}

.tableContainer {
    padding: 1%;
}
.loader {
    margin-left: 100% !important;
}
.nameColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 105px;
    max-width: 105px;
}
.noDataContainer {
    text-align: center !important;
    padding: 26px 0 !important;
    margin: 50% 10%;
}
@media screen and (max-width: 600px) {
    .searchMerchants {
        margin-left: 10%;
        background-color: white;
        width: 250px;
        margin-right: 20px !important;
    }
}
