.qrImage {
    width: 65%;
}
.dialogContent img {
    margin: 0 auto;
    display: block;
}
.qrViewModalTitle {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0 10px;
    .description {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 80%;
        font-size: 19px;
        font-weight: 600;
        display: block;
        text-align: left;
    }
}

.dialog {
    width: 800px;
    height: 600px;
}
.closeIcon {
    float: right;
    text-align: right;
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
        border-radius: 1px;
    }
    overflow-y: hidden;
}
.loading {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 100px;
}
.textInput {
    border: none !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
    text-indent: 0 !important;
    cursor: pointer !important;
    caret-color: transparent;
    color: #2c7be5 !important;
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}
.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    margin-left: -14px;
}
.urlDiv {
    width: 78%;
    margin-top: 16px !important;
    margin-left: 99px !important;
    display: flex;
    justify-content: space-between;
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 599px) {
    .qrImage {
        width: 100%;
        margin-left: 0px !important;
    }
    .qrBtn {
        margin-top: 20px !important;
    }
    .urlDiv {
        margin-left: 0px !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .copyIcon {
        text-align: center;
        margin-left: 0;
    }
}
