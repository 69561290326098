.customInput {
    width: 100%;
    margin-right: 15px;
}
.errorBorder {
    input {
        border: 1px solid red;
    }
}
.withoutBorders {
    input {
        border: none !important;
    }
}

.withoutShadow {
    input:focus {
        box-shadow: none !important;
        border: 2px solid #ff2e58;
    }
    input {
        border: 2px solid #ff2e58;
    }
}
