.customInput {
    width: 100%;
}

.inputLookupCompany {
    background-color: white;
}

.withoutBorders {
    border: 1px solid #fcfdfe;
    border-radius: 4px;
    padding: 5px;
}

.companyContainer {
    align-items: center;
}

.lookupCompanyButton {
    width: 100%;
    height: 40px;
}
.errorSpan {
    color: rgba(0, 0, 0, 0.54);
}

.textButton {
    line-height: 18px;
}
.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 40px;
}
.yesNoSwitch {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
}
