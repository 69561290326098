.HomeWorkItemsComponent {
}
.addBussinesButtonContainer {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.addBusinessButton {
    margin-right: 5px;
    background-image: url('../../assets/icons/add.png');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
}

.title {
    font-size: 25px;
    font-weight: bold;
}

.workList {
    margin-top: 30px;
}

.loading {
    width: 100%;
    text-align: center;
}
