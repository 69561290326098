.creditBtn {
    margin-top: 30px;
}
.popup {
    width: 600px;
}
.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.submitBtn {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }

    max-width: 450px;
}
.requestFormInputRoot {
    width: 100% !important;
}

.requestFormInput {
    border: 1px solid #d2ddec !important;
    border-radius: 4px !important;
    position: relative;
    background-color: white !important;
    font-size: 16px !important;
    padding: 10px !important;
}
.labelText {
    display: flex;
}
.cardInput {
    width: 100%;
    height: 34px !important;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;

    background: none;
    color: #1a3b5d;
    font-family: 'Source Sans Pro', sans-serif;

    fieldset {
        border: none;
        box-shadow: none;
    }
}
.closeIcon {
    float: right;
    text-align: right;
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #f6f7fa;
        border-radius: 1px;
    }
    overflow-y: hidden;
}
.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 600px) {
    .popup {
        width: auto;
    }
}
