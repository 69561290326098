.containerDialog {
    max-width: 650px !important;
}

.headerDivider {
    height: 0.5px;
    width: 100%;
    margin-top: 15px;
    background-color: lightgray;
}

.withdrawalText {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
}
.withdrawalreqText {
    text-align: center;
}
.withdrawalAmount {
    font-weight: bold;
}

.descriptionContainer {
    display: grid;
    margin-top: 10px;
}

.description {
    font-size: 14px;
    color: grey;
}

.button {
    width: 90%;
    margin: 0 5% 20px 5% !important;
}

.codeInputIreland > div > input:first-child,
.codeInputIreland > div > input:nth-child(2) {
    pointer-events: none;
    background-color: lightgray;
}

@media screen and (max-width: 599px) {
    .codeInputIreland {
        width: 250px !important;
    }
    .codeInputIreland > div > input {
        margin-bottom: 2px;
    }
}

.validIcon {
    color: #00cc00;
    margin-top: -5px;
    margin-left: 15px;
    width: 35px !important;
    height: 35px !important;
}

.errorIcon {
    color: red;
    margin-top: -5px;
    margin-left: 15px;
    width: 35px !important;
    height: 35px !important;
}

@media screen and (max-width: 399px) {
    .validIcon {
        margin-top: -65px;
        width: 25px !important;
        height: 25px !important;
    }
    .errorIcon {
        margin-top: -65px;
        width: 25px !important;
        height: 25px !important;
    }
}

.firstWithdrawalContainer {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.alert_message {
    margin-bottom: 20px;
}

.click__here {
    color: blue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.waiting {
    padding: 5px 2px 7px 2px !important;
}

.codeInputIreland > div > input:first-child,
.codeInputIreland > div > input:nth-child(2) {
    pointer-events: none;
    background-color: lightgray;
}

@media screen and (max-width: 599px) {
    .codeInputIreland {
        width: 250px !important;
    }
    .codeInputIreland > div > input {
        margin-bottom: 2px;
    }
}

.validIcon {
    color: #00cc00;
    margin-top: -5px;
    margin-left: 15px;
    width: 35px !important;
    height: 35px !important;
}

.errorIcon {
    color: red;
    margin-top: -5px;
    margin-left: 15px;
    width: 35px !important;
    height: 35px !important;
}

@media screen and (max-width: 399px) {
    .validIcon {
        margin-top: -65px;
        width: 25px !important;
        height: 25px !important;
    }
    .errorIcon {
        margin-top: -65px;
        width: 25px !important;
        height: 25px !important;
    }
}

.firstWithdrawalContainer {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
