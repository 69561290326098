.spanLabel {
    color: #43425d;
    font-weight: 500;
    font-size: 1.25rem;
}
.spanErrorMessage {
    color: red;
}
.sender {
    font-weight: 500;
    color: #8783b5;
    font-size: 1.25rem;
}
.action_items {
    &__account_balance {
        height: 48px;
        display: flex;
        background-color: #f6f7fa;
        border: 1px solid #e4e5e7;
        align-items: center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 10px;
        font-size: 25px;
        font-weight: bold;

        &__currency {
            color: #a0aac1;
        }
    }
    &__input {
        height: 48px;
        &__balance {
            color: #a0aac1;
            font-size: 20px;
            font-weight: bold;
            margin-right: -8px;
        }
    }
}
.inputAmount {
    height: 100% !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
