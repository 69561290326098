.buttonGroupDirection {
    display: flex;
    border: 1px solid #e8e9ec;
    border-radius: 4px;
}

.inputDescription {
    width: 86px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    border-left: 1px solid #e8e9ec;
    background-color: #eaefef;
}

.subTitle {
    padding-bottom: 0 !important;
    padding-top: 30px !important;
}

.numberFormatInput {
    background-color: white;
    height: 34px;
}
