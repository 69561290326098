.btnContainer {
    margin-right: 10px;
}
.textfield {
    width: 100%;
}
.closeIcon {
    margin-left: auto;
    margin-right: 2px;
}
.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.secondaryActionBtn {
    width: 48%;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}

.primaryActionBtn {
    width: 48%;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
