.container {
    padding: 0 3% 100px 0;
    overflow: auto;
    width: 100% !important;
}

.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.noneData {
    text-align: center;
    padding-bottom: 118px !important;
    padding-top: 118px !important;
}

.TableContainer {
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    overflow-x: auto;
    width: 100%;
}

.searchButton {
    margin-left: 15px !important;
}

.searchMerchants {
    background-color: white;
    min-width: 300px !important;
    margin-right: 20px !important;
}
.search_form {
    margin: 20px 20px;
}
.rowstyle {
    cursor: pointer;
}
.inputlabel {
    margin-left: 10px;
}

.adeynFilter {
    width: 10px;
    min-width: 210px;
    margin-left: 10px;
}

.adyenFilterInputProps {
    font-weight: 500 !important;
    border-radius: 4px !important;
    min-width: 190px !important;
    height: 40px;
    padding: 11.5px 14px !important;
    font-size: 0.875rem !important;
    width: 100% !important;
    position: relative;
    background-color: #fff !important;
    text-indent: 10px;
}

.copyIconContainer {
    display: flex;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}

.textInput {
    border: none !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
}

@media screen and (min-width: 320px) and(max-width: 599px) {
    .searchMerchants {
        min-width: 200px !important;
    }
}
@media screen and (max-width: 320px) {
    .searchMerchants {
        min-width: 100px !important;
    }
}
