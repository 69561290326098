.stepperForPreview {
    margin-top: 15px;
    background-color: #fff !important;
    .MuiMobileStepper-dot {
        border: 1px solid currentColor;
        background-color: #fff;
    }
    .MuiMobileStepper-dotActive {
        background-color: #43425d;
    }
}
