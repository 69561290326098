.container {
    height: 100%;
}

.merchantListContainer {
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.merchantDashBoardMenu {
    margin-top: 30px !important;
}

.chargebackContainer {
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
    min-height: 350px;
}
