.merchantListContainer {
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    max-height: 60px !important;
}

.merchantDashBoardMenu {
    margin-top: 40px !important;
}

.headerContainer {
    margin-left: 0px !important;
    margin-right: 30px !important;
}
