.header {
    background-color: rgb(238, 238, 238);
    z-index: 0;
}
.top {
    margin: 10px;
}
.tableDataCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}
.tableRefundCell {
    max-width: 200px !important;
    min-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.row {
    z-index: 0 !important;
}
.mainContainer {
    margin-top: 30px;
    padding: 0 !important;
    border-top: 1px #b0b0b0 solid;
}
.calenderDiv {
    display: flex;
    width: 100%;
    color: #8783b5;
    z-index: 10;
}
.refundsNone {
    text-align: center;
    padding-bottom: 118px;
    padding-top: 118px;
}
.rangeDiv {
    text-align: center;
    width: 100%;
    margin: auto;
    user-select: none;
    padding: 5px 5px !important;
}
.rangeSelect {
    font-size: 16px !important;
    padding-right: 20px;
    text-align: right !important;
}

.TableContainer {
    margin-top: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}
.navButton {
    margin: auto;
    text-transform: 'none';
}
.refundProcess {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}

.viewButton:hover {
    background-color: #43425d !important;
    color: white;
}
.viewButtonName {
    text-transform: none;
}
.processRefundButton {
    text-transform: none;
    width: 115px;
}

.refundContainer {
    margin-top: 10px;
    border-radius: 4px !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e8e9ec;
}
.searchArea {
    margin: 40px 30px;
}
.searchButton {
    margin-left: 15px !important;
}
.divTitle {
    box-shadow: inset 0 -1px #e3e8ee;
    padding: 16px;
}
.title {
    font-size: 21px;
    font-weight: bold;
}
.tableSearch {
    padding: 5px 15px !important;
    margin: 5px;
}
.searchMerchants {
    background-color: white;
    min-width: 300px !important;
    margin-right: 20px !important;
}
.search_form {
    margin: 20px 5px;
}
