.inputs {
    border: 1px solid #e8e9ec;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
    Label {
        margin-top: 15px;
        color: #43425d;
    }
}
.pageTitle {
    padding-left: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.container {
    margin-top: 50px;
    &_title {
        font-weight: bold !important;
        font-size: 21px !important;
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }
}
.qrCode {
    padding: 10px 20px 20px 20px;
}

.loading {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 100px;
}

.qrBtn {
    margin-bottom: 15px !important;
}
.pdfbtn {
    margin-top: 15px !important;
}
.btncontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.qrImage {
    width: 55%;
    margin-top: 16px !important;
    margin-left: 20px !important;
}

.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
}

.paymentUrl {
    width: 50%;
    margin-top: 16px !important;
    margin-left: 20px !important;
}

.textInput {
    border: none !important;
    box-shadow: none !important;
    font-size: 0.875rem !important;
    text-indent: 0 !important;
    cursor: pointer !important;
    caret-color: transparent;
    color: #2c7be5 !important;
    text-decoration: none !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

@media screen and (max-width: 599px) {
    .qrImage {
        width: 100%;
        margin-left: 0px !important;
    }

    .paymentUrl {
        margin-left: 0px !important;
    }

    .copyIcon {
        text-align: center;
    }

    .qrBtn {
        margin-top: 20px !important;
    }
}
