.datePicker {
    width: 100% !important;
    margin-top: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 23px !important;
    > div {
        display: flex;
        gap: 20px;
    }
    margin-bottom: 5px;
}
.midInput {
    border: 1px solid #e8e9ec !important;
    padding: 6px 4px;
    border-radius: 5px;
    height: 40px;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
    margin-bottom: 15px;
}
.dialogbtn {
    height: max-content;
    border: 2px solid rgba(67, 66, 93, 0.25);
    border-radius: 13%;
    padding: 5px;
    background: unset;
    &[disabled] {
        background-color: gainsboro !important;
    }
}
.editContainer {
    display: flex;
}
.disabledInput {
    background-color: gainsboro !important;
}
@media screen and (max-width: 519px) {
    .datePicker {
        > div {
            display: flex;
            gap: 25px;
        }
        width: 100% !important;
        margin-top: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
    }
    .paraText {
        width: 2.5rem !important;
    }
}
