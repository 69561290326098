.merchantHeader {
    margin-top: 10px;
    height: 100px;
    justify-content: left;
    display: flex;
    justify-content: center;
}

.homeLogo {
    height: 100%;
    width: 120px !important;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: inherit;
    }
}
