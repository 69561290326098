.menuText {
    margin-left: 15px !important;
    color: #212529 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.disableMenuText {
    margin-left: 15px !important;
    color: #9897bf !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    pointer-events: none;
}

.separator {
    font-size: 10px;
}

.activeMenu {
    border-left: 3px solid currentColor !important;

    .menuText {
        color: #43425d !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        margin-left: 15px;
    }
    .disableMenuText {
        color: #9897bf !important;
        pointer-events: none;
    }
}

.menuItemContainer {
    margin-left: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.disableMenuContainer {
    color: #9897bf;
    pointer-events: none;
}

.merchantIdMenuItemContainer {
    margin-left: 30%;
}
.copyIcon {
    cursor: pointer;
    font-size: 1.1rem !important;
    margin-left: 5px;
}

.arrowBack {
    width: 30px !important;
    height: 30px !important;
    margin-left: auto;
    cursor: pointer;
}

.arrowBack:hover {
    background-color: lightgray;
    border-radius: 50%;
}

.nestedMenuText {
    margin-left: 45px !important;
    color: #8783b5 !important;
    font-weight: 500 !important;
    width: max-content;
    font-size: 15px !important;
}

.headerContainer {
    height: 100px !important;
    margin-bottom: 20px;
}

.headerItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconSize {
    width: 20px !important;
    height: 20px !important;
}
.languageSelect {
    border-radius: 2px;
    border: 1px lightgray solid;
    color: #212529 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
