.chargebackNoteDetailsWrapper {
    display: flex;
    flex-direction: row;
}
.avatar {
    margin-top: 20px;
    margin-right: 10px;
}

.reporterOrg {
    margin-left: 10px;
    margin-right: 20px;
}
.chargebackNoteHolder {
    border-radius: 4px;
    padding: 10px 10px;
    background-color: lightgray;
}
.chargebackNote {
    padding-left: 10px;
}
.proofOfDamageWrapper {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    background-color: whitesmoke;
    padding: 10px 10px;
    margin: 10px 10px;
    :nth-child(2) {
        margin-left: 5px;
        margin-right: 15px;
    }
}
.proofOfDamageLabel {
    margin: 0 10px 0 10px;
    font-weight: 500;
    font-size: 12px !important;
}
.proofStats {
    color: gray;
    font-size: 12px !important;
}
