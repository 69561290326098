.documentManagementContainer {
    margin-top: 30px;
}
.pageTitle {
    padding: 16px;
}

.documentManagementHeader {
    font-size: 20px !important;
    font-weight: bold !important;
}

.managementArea {
    display: flex;
}

.merchantDetails {
    max-width: 30%;
    flex-basis: 30%;
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.documentManagementButtonsContainer {
    display: flex;
    justify-content: center;
    & button {
        margin: 0 20px;
    }
}

.noteInput {
    padding-bottom: 10px !important;
    padding-top: 10px;
}
.icon {
    margin-left: auto !important;
    margin-right: 1px !important;
    margin-top: 15px !important;
}
.documentTitle {
    margin-bottom: 10px !important;
}
.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.MerchantDetailsContainer {
    display: flex;
    flex-direction: column;
}

.subTitle {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.title {
    padding: 20px 0px;
}

.businessDetailContainer {
    max-width: 100%;
    padding: 20px;
}

.ownerDetailsContainer {
    max-width: 100%;
    padding: 20px;
}

.phoneInput {
    max-width: 100%;
    background-color: gainsboro !important;
    border: none !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.selectMerchantDetails {
    border-radius: 4px !important;
    background-color: white;
    min-width: 250px !important;
    height: 40px;
}

.bankDetails {
    max-width: 100%;
    padding: 20px;
}

.codeInput {
    input:disabled {
        background-color: gainsboro;
        color: rgba(0, 0, 0, 0.38);
    }
}

.imageStyle {
    max-width: 700px;
    max-height: 600px;
}

.dateOfBirthWrapper {
    display: flex;
    flex-direction: row;
    :not(:last-child) {
        margin-right: 10px;
        padding-right: 0;
    }
}
.numberFormatInput {
    background-color: white;
    height: 34px;
}
.container {
    text-align: center;
    margin-top: 7px;
}

.numberFormatInput {
    background-color: white;
    height: 34px;
}
.containsErrorDOB {
    border: 1px solid red !important;
    > fieldset {
        border: 1px solid red !important;
    }
}
.errorview {
    margin: 20px 30px;
}
.dialogWrapper {
    padding: 5px;
    &__dialogBody {
        background-color: #f9fbfd;
        display: flex;
        text-align: center;
        &__titleBar {
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            &__merchantName {
                margin-right: 5px;
                font-size: 20px;
                margin-left: auto;
                &__link {
                    margin-left: 10px;
                    font-style: normal;
                    text-decoration: underline;
                    font-size: 18px;
                    border: 1px solid grey;
                    border-radius: 5px;
                    padding: 0 4px 0 4px;
                }
            }
            &__fileName {
                font-size: 20px;
                padding: 0 5px 0 5px;
            }
            &__documentNumber {
                font-size: 20px;
                margin-left: 10px;
                margin-right: auto;
                text-align: center;
            }
        }
        &__imageWrapper {
            height: 600px;
            margin: 0 auto 5px auto;
            &__imageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #e2e0e0;
                height: 100%;
                &__imageDiv {
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &__text,
                &__loading {
                    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                    font-size: 20px;
                    text-align: center;
                }
                &__loading {
                    color: grey;
                    font-size: 20px;
                }
                &__iframe {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__navArrow {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                &__button {
                    height: 100%;
                }
            }
        }
        &__actionsWrapper {
            margin: auto;
            padding: 5px;
            margin-bottom: 5px;
            &__button {
                flex-basis: 32%;
            }
        }
        &__notesWrapper {
            padding: 5px;
            margin: 0 auto 5px auto;
            &__button {
                margin: 5px auto 0 auto !important;
                min-width: 200px !important;
                width: 32%;
            }
            &__errorMsg {
                margin: 5px auto 0 auto;
                width: 100%;
                justify-content: center;
            }
        }
    }
}
