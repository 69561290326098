@media screen and (max-width: 959px) {
    .steps {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 599px) {
    .steps {
        margin-right: 30px !important;
        margin-left: 30px !important;
    }
}

.formContainer {
    margin-top: -17px !important;
}

.headerContainer {
    height: 160px;
    align-items: center;
}

@media screen and (max-width: 959px) {
    .headerContainer {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .listItemContainer {
        margin: 0 7px 0 7px;
        height: auto;
        border-radius: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #fdf2ee;
        border: 2px solid #deb4a9;
    }
    .bubbleMessage {
        font-size: 14px !important;
    }
}
