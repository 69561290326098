.cardContainer {
    width: 70%;
    max-width: 500px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 40px;
}
.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    height: 34px;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

@media screen and (max-width: 599px) {
    .cardContainer {
        width: 90%;
    }
}

.cardFormContainer {
    margin: 30px 10px;
    padding-top: 0 !important;
    h1 {
        font-size: 24px;
        font-weight: bold;
    }
}
.footerText {
    text-align: center;
    padding: 10px;
    color: slategray;
    font-size: small;
}
.headerText {
    color: slategray;
}
.cardInput {
    width: 100%;
    height: 34px !important;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: 'Source Sans Pro', sans-serif;

    &:hover,
    &:focus {
        border-color: #3d9cff;
    }

    &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 90% center;
        background-repeat: no-repeat;
        padding-right: 30px;
    }

    fieldset {
        border: none;
        box-shadow: none;
    }
}

.proceedViaCard,
.proceedViaLink {
    color: #fff;
    background-color: #43425d;
    border: none;

    &:hover {
        background-color: #43425d;
    }

    &:disabled {
        color: #aaaaaa;
        background-color: #d8d9dc;
    }
}

.paymentOptionContainer {
    display: flex;
    justify-content: space-around;
    .paymentBox {
        width: 100%;
        padding: 1em 0.4em;
        border: 1px solid lightgrey;
        font-size: 14px;
        font-weight: 600;
        border-radius: 0.4em;
        &:hover {
            background: rgb(222, 216, 216);
        }
        &.select {
            background: #f3f3f3;
        }
        @media screen and (max-width: 599px) {
            font-size: 11px;
        }
        @media screen and (max-width: 460px) {
            font-size: 8px;
        }
    }
}
