.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}
.homeLogo {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 170px;
    }
}

.content {
    max-width: 500px !important;
}

.textBold {
    font-weight: bold !important;
    font-size: 16px !important;
    cursor: pointer;
}

.linkFont {
    color: #6e6ca8 !important;
}

@media screen and (max-width: 599px) {
    .content {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

@media screen and (min-width: 599px) {
    .submitOtpFormWrapper {
        margin-left: 25%;
    }
}

@media screen and (max-width: 600px) {
    .homeLogo {
        height: 170px;
        img {
            width: 130px;
        }
    }
}

.linkNewRequest {
    text-decoration: underline;
    color: darkblue;
}

.validationNote {
    padding-top: 60px;
}

@media screen and (max-width: 599px) {
    .validationNote {
        padding-top: 20px;
        margin-bottom: 40px;
        justify-content: center;
    }
}

.password_errors {
    margin-bottom: 10px;
}

.primaryActionBtn {
    background-color: green !important;
    color: white !important;
}
