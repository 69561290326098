.newMerchantContainer {
    width: 100%;
    margin-top: 30px;
    padding-left: 0px !important;
}
.headerDiv {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    align-items: center;
}
.legalNameStyles {
    margin-top: 35px !important;
    padding: 5px;
    width: 207%;
    flex-grow: 0;
    max-width: 109%;
    flex-basis: 50%;
    width: 201%;
}

.pageTitle {
    font-weight: bold;
    font-size: 22px;
    margin: 0px;
}

.formContainer {
    width: 88%;
    margin-left: 10%;
    max-width: 450px;
    flex-grow: 25;
    flex-basis: 50%;
    margin-bottom: 25px !important;
}

.btnContainer {
    text-align: center;
    display: flex;
    margin-bottom: 40px !important;
}

.legalNameStyles1 {
    margin-top: 13px !important;
    padding: 5px;
    width: 207%;
    flex-grow: 0;
    max-width: 201%;
    flex-basis: 50%;
    width: 201%;
}

.closeButtonStyle {
    font-size: 20px !important;
    margin-right: 5px;
    cursor: pointer;
    color: grey !important;
}

.submitBtn {
    width: 75%;
    max-width: 450px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.primaryActionBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
.secondaryActionBtn {
    width: 90px;
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}
.secondaryButtonColor {
    background-color: #005e9a !important;
    color: #fff !important;
}

.primaryButtonColor {
    background-color: #039be5 !important;
    color: #fff !important;
}

.addressContainer {
    margin-bottom: 40px !important;
}

.margin {
    margin-right: -8px;
}
.balanceCurrencySign {
    color: #a0aac1;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    height: 34px;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

.disabledPhoneInput {
    color: rgba(0, 0, 0, 0.38) !important;
}
.textFieldMid {
    border: 1px solid #e8e9ec !important;
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    height: 34px;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}

.dropDown {
    margin-top: 20px;
    flex-grow: 1;
}
.customOutlineInput {
    border-radius: 4px;
    font-size: 16px;
    height: 35px;
    width: 100%;
}

.multiSelectCss {
    height: 45px;
}

.notificationMessageContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.currencyStyle {
    background-color: gainsboro;
    border-radius: 4px;
    border: 1px solid #cacaca;
    padding: 5px 25px;
    color: grey;
}

@media screen and (max-width: 599px) {
    .formContainer {
        margin-left: 6%;
    }
}
