.backgroundColor {
    background-color: #f6f7fa;
}
.container {
    height: 100%;
}

.adminDashboardMenu {
    margin-top: 30px !important;
}

.dashboardContentContainer {
    margin-top: -47px !important;
    // margin-right: 50px !important;
}
