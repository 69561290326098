.bannerIcon {
    font-size: 20px;
    color: #f44336;
}

.bannerIconContainer {
    margin: 8px 16px;
}

.bannerContainer {
    background-color: #fdf2ee;
    border-radius: 4px;
    border: 2px solid #deb4a9;
    margin: 20px 0 1px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.bannerText {
    font-size: 15px;
    margin: 10px 0;
}
