.modalUser {
    .modalDialog {
        float: right;
        right: 20px;
        top: 50px;
        margin: 0;
        width: 300px;
    }

    .userInfo {
        width: 100%;
        text-align: center;

        .picture {
            margin-bottom: 10px;
        }

        .email {
            margin: 0;
        }

        .manageProfile {
            border: 1px lightgray solid;
            border-radius: 4px;
            margin: 20px 30px 0 30px;
            cursor: pointer;
        }
        .manageProfile:hover {
            background-color: rgb(245, 245, 245);
        }
    }

    .modalBody {
        padding: 0;

        .listItem {
            padding: 0;
            height: 40px;
            padding-left: 30px;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }
    }

    .modalFooter {
        .logout {
            width: 100%;
            height: 37px;
            text-align: center;
            border: 1px lightgray solid;
            border-radius: 4px;
            margin: 0 90px;
            padding-top: 5px;
            cursor: pointer;
        }
        .logout:hover {
            background-color: rgb(245, 245, 245);
        }
    }
}
