.paper {
    width: 50%;
    max-width: 500px !important;
    min-width: 250px !important;
}

.secondaryActionBtn {
    &:not([disabled]) {
        background-color: #43425d !important;
        color: white !important;
    }
}

.primaryActionBtn {
    &:not([disabled]) {
        background-color: green !important;
        color: white !important;
    }
}
