.paymentsNone {
    padding: 32px 16px;
    text-align: center;
}
.resultSpan {
    margin-left: 10px;
    padding-bottom: 10px;
}
.currencySpan {
    padding-left: 5px;
    color: gray;
}
.actionHolder {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 599px) {
    .tableRow > * {
        padding: 5px !important;
    }
}
