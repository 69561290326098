.merchantCreationTitle {
    font-weight: bold;
    font-size: 17px;
    margin-left: 5px;
    background-color: #aed6f9;
    margin: 0;
    padding: 1rem;
}

.modalContainer {
    width: 95%;
}
