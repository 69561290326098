.mainDiv {
    display: grid;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.imageDiv {
    display: flex;
    justify-content: flex-start;
    justify-self: center;
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 80%;
}
.maintainenceMessage {
    font-size: 1.4em;
    font-weight: 400;
    color: #015895;
    text-align: center;
}
.logoImage {
    width: 30%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
}

.svg {
    margin-top: 0;
    border-color: red;
    border-width: 10px;
}
.omniLogo {
    width: 15%;
    margin: 10% auto 10%;
}

@media (max-width: 600px) {
    .omniLogo {
        width: 40%;
        margin: 20% auto;
    }
    .logoImage {
        width: 30%;
        margin: 20% auto;
    }
    .svg {
        width: 80%;
    }
}
.copyRigthDiv {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    font-size: xx-small;
}
