$line_color: #f5f7fd;
$link_color: #6e6ca8;
table {
    border-collapse: collapse;
}
tr {
    border: none;
}
td {
    border-left: solid 0.2px $line_color;
}

.info_bar {
    display: flex !important;
    padding: 16px;
}
.info_bar_text {
    padding-left: 20px;
}

.float_right {
    margin-left: auto !important;
}

.click_effect {
    &:active {
        transform: translateY(1px);
    }
    cursor: pointer;
}

.info_ico_bar {
    border-top: solid 0.2px $line_color;
    display: flex !important;
    padding: 14px;
}

.info_ico {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 12px;
}
.link_font {
    color: $link_color !important;
}
