$line_color: #f5f7fd;
$link_color: #6e6ca8;
$font_color: #43425d;

th,
td {
    border-left: solid 0.2px $line_color;
    font-size: 12px;
}

.info_bar {
    display: flex !important;
    padding: 16px;

    &__text {
        display: inline;
        color: $font_color;
        font-size: 1.5rem !important;
        font-weight: 700 !important;
    }
    &__icon {
        padding-right: 2px;
        font-size: 16px !important;
        cursor: pointer;
    }
}
.float_right {
    margin-left: auto !important;
}

.unfold_icon {
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 0;

    &:active {
        transform: translateY(1px);
    }
}
.click_effect {
    &:active {
        transform: translateY(1px);
    }
    cursor: pointer;
}

.link_font {
    color: $link_color !important;
}

.bottom_bar {
    display: block;
    height: 50px;
}
