.product {
    font-size: 1.5em;
    font-weight: bold;
}

.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.switch {
    background-color: #43425d;
}

.yesNoSwitch {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
}

.checkBoxText {
    font-size: 14px !important;
}

.paddingTop {
    display: flex;
    padding-top: 30px;
    justify-content: space-evenly;
    align-items: center;
}

.timeInBusiness {
    padding: 0px !important;
}

.productDescriptions {
    margin-top: 10px !important;
    margin-left: 10px !important;
}

.numberFormatInput {
    background-color: white;
    height: 34px;
}
