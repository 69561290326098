.primaryOwner {
    font-size: 1.5em;
    font-weight: bold;
}

.formItemGrid {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dateOfBirthWrapper {
    display: flex;
    flex-direction: row;
    :not(:last-child) {
        margin-right: 1px;
        padding-right: 0;
    }
}

.selectContainer {
    margin-top: 10px !important;
}

.ownershipLabel {
    margin-bottom: 8px !important;
}

.numberFormatInput {
    background-color: white;
    height: 34px;
}
.containsErrorDOB {
    border: 1px solid red !important;
    > fieldset {
        border: 1px solid red !important;
    }
}
.nameInput {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
}
.space {
    margin-right: 20px;
}

.phoneInput {
    width: 100% !important;
    border: 1px solid #e8e9ec !important;
    &:focus {
        outline: 0;
        box-shadow: rgba(67, 66, 93, 0.25) 0 0 0 0.2rem !important;
        border-color: #43425d !important;
    }
}
.invalidNationality {
    border: 2px solid #ff2e58 !important;
    border-radius: 4px;
    box-shadow: none !important;
}

.phoneInputEmpty {
    border: 2px solid #ff2e58 !important;
    width: 100% !important;
}

.notificationMessageContainer {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.emptyBlock {
    margin-top: 5px;
    visibility: hidden;
}

.fullOuterBox {
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
}

.fullOuterBox label {
    margin-left: 1px;
}
.fullOuterBox .MuiOutlinedInput-input {
    padding: 7.5px;
}
.monthSelect {
    height: 34px !important;
}
.unselectedDropdownErrorStyle {
    border: 2px solid #ff2e58 !important;
    border-radius: 4px;
    box-shadow: none !important;
}

@media screen and (max-width: 640px) {
    .nameInput {
        display: block;
        width: 100%;
        justify-content: space-around;
        margin-top: 13px !important;
    }
    .space {
        margin: 25px 0 0;
    }
}
